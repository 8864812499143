@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.caption {
  color: var(--caption--color);
  font-family: var(--caption--font-family);
  font-size: t.$caption--font-size;
  font-style: t.$caption--font-style;
  font-weight: t.$caption--font-weight;
  letter-spacing: t.$caption--letter-spacing;
  line-height: var(--caption--line-height);
  position: relative;
}

.caption::before {
  margin: 0 h.rem(20px) 0 0;
  border-bottom: h.rem(1px) solid var(--caption--before--border-bottom-color);
  content: '';
  display: inline-block;
  top: h.rem(10px);
  vertical-align: middle;

  @include h.responsive-variations(width, (s: h.rem(20px), m: h.rem(40px)));

  body.select & {
    width: h.rem(39px);

    @include h.responsive-variations(width, (s: 0 h.rem(8px) 0 0, m: 0 h.rem(8px) 0 h.rem(20px)));
  }

  body.today & {
    content: none;
  }
}

.caption__container {
  margin: var(--caption--container--margin);
}

.caption__source {
  color: var(--caption--source--color);
  display: inline-block;
  font-family: var(--caption--source--font-family);
  font-size: t.$caption--source--font-size;
  font-weight: var(--caption--source--font-weight);
  letter-spacing: var(--caption--source--letter-spacing);
  margin: var(--caption--source--margin);
  word-spacing: var(--caption--source--word-spacing);
}
