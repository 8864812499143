@use 'assets/styles/utils/helper' as h;

// :root to allow overrides from other scoped pages/components
:root {
  --article-dek--color: var(--white);
  --article-dek--font-family: var(--publico-hed);
  --article-dek--font-size: var(--text-16);
  --article-dek--font-weight: var(--light);
  --article-dek--line-height: var(--leading-150);
  --article-dek--letter-spacing: normal;
  --article-dek--margin: #{h.rem(10px)} 0 0;

  // Dek with image
  --article-dek--with-image--margin: #{h.rem(10px)} 0;

  // Used in Liveblog & Breaking News Article
  --article-dek--font-size--compact: var(--article-dek--font-size);
  --article-dek--line-height--compact: var(--article-dek--line-height);
  --article-dek--margin-bottom--compact: #{h.rem(14px)};

  @include h.breakpoint-m {
    --article-dek--font-size: var(--text-18);
    --article-dek--margin: #{h.rem(12px)} 0 0;
    --article-dek--with-image--margin: #{h.rem(12px)} 0 #{h.rem(24px)};
    --article-dek--margin-bottom--compact: #{h.rem(20px)};
  }

  @include h.breakpoint-l {
    --article-dek--font-size: var(--text-20);
    --article-dek--font-size--compact: var(--text-18);
  }

  @include h.breakpoint-x {
    --article-dek--font-size: var(--text-24);
    --article-dek--font-size--compact: var(--text-20);
    --article-dek--line-height--compact: var(--leading-125);
  }

  :global(body.today),
  :global(body.telemundo),
  :global(body.entretenimiento),
  :global(body.shows) {
    --article-dek--color: var(--grey-60);
  }

  :global(body.today) {
    --article-dek--font-family: var(--tertiary-font);
    --article-dek--font-size: #{h.rem(20px)};
    --article-dek--font-weight: var(--normal);
    --article-dek--with-image--margin: #{h.rem(10px)} 0 0;
    --article-dek--font-size--compact: #{h.rem(20px)};
    --article-dek--line-height--compact: 150%;

    @include h.breakpoint-m {
      --article-dek--font-size--compact: 24px;
    }

    @include h.breakpoint-l {
      --article-dek--font-size: #{h.rem(24px)};
    }
  }

  :global(body.select) {
    --article-dek--color: #2a2a2a;
    --article-dek--font-family: var(--lato);
    --article-dek--font-size: #{h.rem(14px)};
    --article-dek--font-size--compact: var(--article-dek--font-size);
    --article-dek--font-weight: 700;
    --article-dek--line-height: 1.2;
    --article-dek--line-height--compact: var(--article-dek--line-height);

    // Dek with image
    --article-dek--with-image--margin: 0;

    @include h.breakpoint-m {
      --article-dek--font-size: #{h.rem(18px)};
      --article-dek--line-height: 22px;
      --article-dek--font-size--compact: #{h.rem(17px)}; // TODO update with fixed tokens
      --article-dek--line-height--compact: #{h.rem(20px)};
    }

    @include h.breakpoint-x {
      --article-dek--font-size: #{h.rem(20px)};
      --article-dek--line-height: var(--leading-150);
      --article-dek--font-size--compact: #{h.rem(18px)}; // TODO update with fixed tokens
      --article-dek--line-height--compact: #{h.rem(22px)};
    }
  }

  :global(body.today .article-hero__presentation-wide) {
    --article-dek--font-size: #{h.rem(20px)};

    @include h.breakpoint-m {
      --article-dek--font-size: #{h.rem(18px)};
    }
  }
}
