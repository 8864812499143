/* stylelint-disable at-rule-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown  */
/* stylelint-disable property-no-unknown  */
/* stylelint-disable unit-no-unknown  */

.slate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: radial-gradient(72.09% 72.09% at 50% 17.15%, #3a4856 0%, #29333c 40%, #0e161a 100%);
  container: errorSlate / inline-size;
  aspect-ratio: 16 / 9;
}

.mainMessage,
.detailedMessage {
  font-family: var(--founders-cond);
  font-weight: bold;
  user-select: none;
  margin: 0;
  text-align: center;
  color: var(--white);
}

.mainMessage {
  font-size: calc(6.2cqi + 2px);
  margin-top: calc(-2.6cqi - 26px);
}

.detailedMessage {
  font-size: calc(4.2cqi + 2px);
  padding-top: 6px;
  width: 75%;
}

.connection-error-slate {
  .detailedMessage {
    width: 66%;
  }
}

// playmakerEmbed
@container errorSlate (min-width: 960px) {
  .connection-error-slate {
    .mainMessage {
      font-size: 4.8cqw;
    }

    .detailedMessage {
      width: 70%;
      padding-top: 20px;
      font-size: 3.6cqw;
    }
  }
}
