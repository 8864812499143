@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.unibrow__external-link-container {
  color: var(--black);
}

.unibrow__external-link-icon {
  margin-left: 10px;
  vertical-align: top;

  .unibrow__external-link-path {
    fill: var(--black);
  }
}

.unibrow-logo {
  display: flex;

  @include h.responsive-variations(flex-direction, (s: row, x: column));
  @include h.responsive-variations(align-items, (s: center, x: stretch));

  .unibrow-logo__image {
    @include h.responsive-variations(padding-right, (s: 12px, x: none));
    @include h.responsive-variations(margin-right, (s: 16px, x: none));
    @include h.responsive-variations(padding-bottom, (s: none, x: 12px));
    @include h.responsive-variations(margin-bottom, (s: none, x: 6px));
    @include h.responsive-variations(width, (s: 60px, m: 80px));
  }

  .unibrow-logo__image-border {
    @include h.responsive-variations(border-right, (s: solid 1px, x: none));
    @include h.responsive-variations(border-bottom, (s: none, x: solid 1px));
  }
}

.entretenimiento {
  .unibrow-logo {
    .unibrow-logo__image-border {
      @include h.responsive-variations(border-right, (s: solid 1px rgba(35, 40, 60, 0.2), x: none));
      @include h.responsive-variations(border-bottom, (s: none, x: solid 1px rgba(35, 40, 60, 0.2)));
    }
  }
}

.unibrow--breaking-news,
.unibrow.unibrow--breaking-news span {
  color: var(--red-50);
}

.unibrow--default {
  color: var(--unibrow--default--color);
  font-size: var(--unibrow--default--font-size);
  font-family: var(--unibrow--default--font-family);
  font-weight: var(--unibrow--default--font-weight);
  line-height: var(--unibrow--default--line-height);
  text-transform: var(--unibrow--default--text-transform);
  margin: var(--unibrow--default--margin);

  a:hover {
    color: var(--secondary-color);
  }
}
