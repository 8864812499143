@use 'assets/styles/utils/helper' as h;

$title-logo-height-storyline: 18px;
$title-logo-space-24: 24px;
$title-logo-space-32: 32px;
$title-logo-space-40: 40px;
$title-logo-space-60: 60px;
$title-logo-space-80: 80px;
$title-logo-space-90: 90px;
$title-logo-space-100: 100px;
$title-logo-space-150: 150px;
$title-logo-space-160: 160px;
$title-logo-space-200: 200px;
$title-logo-space-220: 220px;
$title-logo-space-300: 300px;
$title-logo-space-500: 500px;

.titleLogo {
  @include h.object-fit(contain);

  .alacarte &,
  .bacon__package & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-24;
  }

  .pancake & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-32;
  }

  .multiUp &,
  .multiUpRail &,
  .twoUp &,
  .threeUp &,
  .fourUp & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-40;
  }

  .videoPkg & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-40;

    @include h.breakpoint-x {
      max-width: $title-logo-space-220;
      max-height: $title-logo-space-60;
    }
  }

  .collectionLead &,
  .collectionBillboard & {
    max-width: $title-logo-space-200;
    max-height: $title-logo-space-60;

    @include h.breakpoint-m {
      max-height: $title-logo-space-100;
    }
  }

  .collectionTitle & {
    display: inline;
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-40;

    @include h.breakpoint-m {
      max-width: $title-logo-space-220;
      max-height: $title-logo-space-80;
    }
  }

  .collectionPoster & {
    max-width: $title-logo-space-200;
    max-height: $title-logo-space-60;

    @include h.breakpoint-m {
      max-width: $title-logo-space-160;
      max-height: $title-logo-space-80;
    }

    @include h.breakpoint-l {
      max-width: $title-logo-space-200;
      max-height: $title-logo-space-100;
    }

    @include h.breakpoint-x {
      max-width: $title-logo-space-160;
      max-height: $title-logo-space-80;
    }
  }

  .waffleHeader & {
    margin: auto;

    @include h.breakpoint-m-max {
      max-width: 66%;
      max-height: $title-logo-space-100;
    }

    @include h.breakpoint-l {
      max-width: $title-logo-space-500;
      max-height: $title-logo-space-150;
    }
  }

  .magazineLead & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-40;

    @include h.breakpoint-m {
      max-width: $title-logo-space-160;
      max-height: $title-logo-space-60;
    }
  }

  .leadSectionFront & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-40;

    @include h.breakpoint-m {
      max-width: $title-logo-space-220;
      max-height: $title-logo-space-80;
    }
  }

  .showLead & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-60;

    @include h.breakpoint-m {
      max-width: $title-logo-space-300;
      max-height: $title-logo-space-90;
    }
  }

  .teaseList & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-32;
  }

  .pkg.storyline & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-height-storyline;
  }

  .stickyAdTrack.stickyAdTrack--7up & {
    max-width: $title-logo-space-160;
    max-height: $title-logo-space-60;
  }

  body.today .showLead & {
    max-width: 100%;
    max-height: $title-logo-space-60;

    @include h.breakpoint-m {
      max-width: 100%;
      max-height: $title-logo-space-150;
    }
  }
}
