@use 'assets/styles/utils/helper' as h;

:root {
  --ecommerce-disclaimer--width: 100%;
  --ecommerce-disclaimer--background-color: var(--grey-20);
  --ecommerce-disclaimer--color: var(--grey-60);
  --ecommerce-disclaimer--font-family: inherit;
  --ecommerce-disclaimer--font-size: var(--text-16);
  --ecommerce-disclaimer--line-height: var(--text-24);
  --ecommerce-disclaimer--margin: #{h.rem(24px -20px 30px)};
  --ecommerce-disclaimer--content--font-style: normal;
  --ecommerce-disclaimer--content--margin: 0;
  --ecommerce-disclaimer--content--padding: #{h.rem(20px)};
  --ecommerce-disclaimer--content--text-align: inherit;
  --ecommerce-disclaimer--content--font-weight: inherit;
  --ecommerce-disclaimer--content--line-height: var(--text-24);
  --ecommerce-disclaimer--link--color: inherit;
  --ecommerce-disclaimer--link--border-bottom: none;
  --ecommerce-disclaimer--link--font-weight: 600;
  --ecommerce-disclaimer--link--padding-bottom: 0;
  --ecommerce-disclaimer--link--hover-color: inherit;
  --ecommerce-disclaimer--link--hover-opacity: var(--default-hover-opacity);

  @include h.breakpoint-m {
    --ecommerce-disclaimer--margin: #{h.rem(24px 0 48px -40px)};
    --ecommerce-disclaimer--content--padding: #{h.rem(40px)};
  }

  @include h.breakpoint-l {
    --ecommerce-disclaimer--margin: #{h.rem(24px 0 48px 0)};
  }

  @include h.breakpoint-x {
    --ecommerce-disclaimer--margin: #{h.rem(24px 0 48px -40px)};
  }
}

:global(body.news) {
  --ecommerce-disclaimer--content--font-style: italic;
  --ecommerce-disclaimer--link--color: var(--blue-40);
  --ecommerce-disclaimer--content--text-align: center;
}

:global(body.better) {
  --ecommerce-disclaimer--content--font-style: italic;
  --ecommerce-disclaimer--link--color: var(--teal-30);
}

:global(body.mach) {
  --ecommerce-disclaimer--content--font-style: italic;
  --ecommerce-disclaimer--link--color: var(--purple-50);
}

:global(body.think) {
  --ecommerce-disclaimer--content--font-style: italic;
  --ecommerce-disclaimer--link--color: var(--red-40);
}

:global(body.today) {
  --ecommerce-disclaimer--font-family: var(--tertiary-font);
  --ecommerce-disclaimer--font-size: #{h.rem(14px)};
  --ecommerce-disclaimer--color: var(--grey-60);
  --ecommerce-disclaimer--link--border-bottom: 2px solid var(--accent-orange);
  --ecommerce-disclaimer--link--padding-bottom: 1px;
  --ecommerce-disclaimer--background-color: var(--grey-30);
  --ecommerce-disclaimer--content--font-style: italic;
  --ecommerce-disclaimer--content--font-weight: 700;
  --ecommerce-disclaimer--content--line-height: var(--leading-150);
  --ecommerce-disclaimer--line-height: 150%;
  --ecommerce-disclaimer--content--text-align: left;
  --ecommerce-disclaimer--content--padding: #{h.rem(20px)};
  --ecommerce-disclaimer--link--font-weight: 700;
  --ecommerce-disclaimer--link--hover-color: var(--orange-30);
  --ecommerce-disclaimer--margin: initial;
  --ecommerce-disclaimer--link--hover-opacity: 1;

  @include h.breakpoint-m {
    --ecommerce-disclaimer--content--text-align: center;
    --ecommerce-disclaimer--font-size: #{h.rem(16px)};
    --ecommerce-disclaimer--content--padding: #{h.rem(20px 30px)};
  }

  @include h.breakpoint-l {
    --ecommerce-disclaimer--content--padding: #{h.rem(20px 40px)};
  }
}

:global(body.select) {
  --ecommerce-disclaimer--background-color: #f1f1f1; //can't find an existing token for this one
  --ecommerce-disclaimer--color: var(--neutral-60);
  --ecommerce-disclaimer--content--font-weight: 600;
  --ecommerce-disclaimer--content--line-height: #{h.rem(18px)};
  --ecommerce-disclaimer--content--padding: #{h.rem(20px)};
  --ecommerce-disclaimer--content--text-align: left;
  --ecommerce-disclaimer--font-family: var(--lato);
  --ecommerce-disclaimer--font-size: #{h.rem(12px)};
  --ecommerce-disclaimer--margin: initial;

  @include h.breakpoint-m {
    --ecommerce-disclaimer--content--line-height: #{h.rem(21px)};
    --ecommerce-disclaimer--content--padding: #{h.rem(20px 30px)};
    --ecommerce-disclaimer--content--text-align: center;
    --ecommerce-disclaimer--font-size: #{h.rem(14px)};
  }

  @include h.breakpoint-l {
    --ecommerce-disclaimer--content--padding: #{h.rem(20px 40px)};
  }

  --ecommerce-disclaimer--link--color: var(--blue-60);
}
