.newsNow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;

  .img {
    margin: 0 4px;

    &.live-img {
      margin-top: 2px;
    }

    &.news-now-img {
      margin-top: 9px;
    }

    &.news-now-img-updated {
      margin-top: 3px;
    }

    &.chevron-img {
      margin: 6px 0 0 2px;

      & > svg {
        height: 13px;
        transform: rotate(-90deg);

        path {
          stroke-linecap: initial;
          stroke-width: 1.5;
        }
      }
    }
  }
}
