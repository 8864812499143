@use 'assets/styles/utils/helper' as h;

.baconCardsAuthor {
  display: flex;

  &PhotoGroup {
    margin-right: var(--text-12);
    width: var(--space-60);
  }

  &TextGroup {
    padding-right: var(--space24);
    width: 191px;
  }

  &Photo {
    background-color: #ffbe03;
    border-radius: 50%;
    height: var(--space-60);
    width: var(--space-60);
  }

  &Name {
    display: block;
    font-family: var(--founders-mono);
    font-size: var(--text-12);
    letter-spacing: var(--tracking-tight);
    text-transform: uppercase;
    margin-left: 10px;
    padding-right: 10px;

    & > span {
      background-color: #ffbe03;
      border: 0 solid #ffbe03;
      border-width: 3px 0;
      margin-right: -10px;
      padding-right: 10px;
      position: relative;
      white-space: pre-wrap;

      &::after {
        background-color: #ffbe03;
        bottom: -0.25em;
        content: '';
        position: absolute;
        right: 100%;
        top: -0.25em;
        width: 10px;
      }
    }
  }

  &Headline {
    font-family: var(--founders-cond);
    font-size: var(--text-18);
    font-weight: var(--normal);
    line-height: 100%;
    margin-top: var(--text-12);
  }
}
