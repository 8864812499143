@use 'assets/styles/utils/helper' as h;

.container {
  font-size: 0;
  display: block;
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }
}

.sportsContainer {
  @extend .container;

  @include h.breakpoint-m {
    position: relative;
  }
}

.typeWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
}
