@use 'assets/styles/utils/helper' as h;

:root {
  --bacon-description-height: auto;
  --bacon-font-family: var(--default-font);
  --bacon-header-color: var(--default-color);
  --bacon-header-font-family: var(--default-font);
  --bacon-header-font-size: 20px;
  --bacon-header-font-weight: 100;
  --bacon-header-height: auto;
  --bacon-header-line-height: 100%;
  --bacon-description-font-family: var(--default-font);
  --divider-color: var(--grey-40);
  --hover-color: var(--default-color);
  --price-color: var(--default-color);
  --price-font-weight: 100;
  --seller-text-decoration-color: var(--default-color);
  --bacon-description-font-size: #{h.rem(12px)};
  --bacon-description-color: var(--grey-70);
}

:global(body.today) {
  --bacon-description-color: var(--grey-60);
  --bacon-description-height: 36px;
  --bacon-description-font-family: 'CrimsonPro', Arial, Helvetica, sans-serif;
  --bacon-description-font-size: #{h.rem(14px)};
  --bacon-header-color: var(--grey-70);
  --bacon-header-font-size: 20px;
  --bacon-header-font-weight: 400;
  --bacon-header-height: 50px;
  --bacon-header-line-height: 125%;
  --divider-color: var(--grey-40);
  --hover-color: var(--orange-60);
  --price-color: var(--orange-60);
  --price-font-weight: 700;
  --seller-text-decoration-color: var(--orange-30);
  --bacon-font-family: Mada, sans-serif;
}
