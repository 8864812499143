@use 'assets/styles/utils/helper' as h;
@use 'sass:math';

.baconCardsOneByOne {
  overflow: hidden;
  width: 100%;

  .unibrow,
  .icon {
    transition: transform linear 150ms;
  }

  .icon {
    font-size: 1rem;
    transform-origin: bottom right;

    :global(body.select) &,
    :global(body.today) & {
      font-size: 0.75rem;
    }
  }

  &ProductHeadline {
    font-family: var(---default-headline-font-family);
    font-size: var(--text-24);
    font-weight: 400;
    line-height: 22.5px;
    margin: h.rem(12px) h.rem(16px) 0 0;
    max-height: 22.5px * 3;
    overflow-y: hidden;
    position: relative;

    :global(body.think) &,
    :global(body.today) &,
    :global(body.select) & {
      font-size: 18px;
    }

    :global(body.select) & {
      font-weight: 900;
      line-height: 18px;
    }

    @supports (-webkit-line-clamp: 3) and
      (-webkit-box-orient: vertical) and (display: -webkit-box) {
      overflow: initial;
    }
  }

  &ProductHeadlineLink {
    @include h.line-clamp(3, 22.5px, var(--default-background), true);
    color: var(--grey-70);
    overflow-y: hidden;

    :global(body.select) & {
      @include h.line-clamp(3, 18px, var(--default-background), true);
      color: var(--grey-100);
      text-decoration: none;
    }

    :global(body.today) & {
      color: var(--purple-70);
      font-family: var(--publico-txt);
      text-decoration: none;
    }
  }

  &ProductDescription {
    @include h.line-clamp(2, 12px, var(--default-background));
    color: var(--grey-60);
    font-family: var(--default-text-font-family);
    font-size: var(--text-12);
    line-height: 1.5;
    margin: h.rem(8px) 0 0;
    max-height: 12px * 1.5 * 2;
    overflow-y: hidden;
  }

  &ProductButton {
    display: block;
    text-decoration: none;
    width: h.rem(190px);
  }

  &ProductButtonShop {
    margin: h.rem(16px) 0 0;
  }

  &ProductButtonVendor {
    border-radius: 0;

    :global(body.select) & {
      border-radius: 100px;
    }
  }

  &ProductPrice {
    color: var(--grey-50);
    font-family: var(--founders-mono);
    font-size: 17px;
    font-weight: var(--bold);
    line-height: var(--space-16);
    margin: var(--space-16) 0 0;

    :global(body.select) & {
      color: var(--gray-60);
      font-family: inherit;
    }

    &Sale {
      & > :nth-child(2) {
        font-weight: var(--normal);
        margin: 8px 0 0;
        text-decoration: line-through;
        color: #999;
      }

      & > :nth-child(1) {
        color: var(--grey-50);

        :global(body.better) &,
        :global(body.entretenimiento) &,
        :global(body.noticias) &,
        :global(body.deportes) &,
        :global(body.telemundo) &,
        :global(body.shows) & {
          color: var(--red-30);
        }

        :global(body.select) & {
          color: #cb1111;
        }

        :global(body.today) & {
          color: var(--orange-70);
        }
      }
    }

    &OutOfStock {
      :global(body.select) & {
        color: #999;
      }
    }
  }
}
