@use 'assets/styles/utils/helper' as h;
@import './theme.tokens.scss';

.baconCardsWidthByOne {
  font-family: var(--bacon-font-family);
  padding-left: 12px;
  padding-right: 12px;
  position: relative;

  &::after {
    background-color: var(--divider-color);
    content: '';
    height: 487px;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.2px;
  }

  picture {
    margin-bottom: 8px;
  }
}

.imageAndSaveButtonWrapper {
  position: relative;
}

.productNumbering {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--grey-60);
  margin: 0 0 8px 0;
  height: h.rem(21px);
}

.baconCardsWidthByOneHeader,
.baconCardsWidthByOneHeaderWithDescription {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  color: var(--grey-70);
  margin: 0 0 16px;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: var(--bacon-header-height);
  overflow: hidden;
}

.baconCardsWidthByOneHeaderWithDescription {
  margin: 0 0 8px;
}

.description {
  font-family: var(--bacon-description-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--bacon-description-font-size);
  line-height: 150%;
  color: var(--bacon-description-color);
  margin: h.rem(0 0 16px 0);
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: h.rem(39px);
}

.productSellerWrapper {
  font-family: var(--bacon-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 31px;

  p {
    margin: 0;
  }

  button {
    all: unset;
    font-weight: 400;
    font-style: normal;
    line-height: 100%;
    font-size: 16px;
    color: var(--grey-70);
    text-transform: uppercase;
    height: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .addToCartIcon {
      path {
        fill: var(--grey-70);
      }
      height: 19px;
      width: 19px;
      margin-right: 5px;
    }

    &:hover {
      color: var(--orange-30);

      .addToCartIcon {
        path {
          fill: var(--default-color);
        }
      }
    }

    .addToCart {
      border-bottom: 2px solid #ffb186;
    }
  }

  .listPriceWithSale {
    font-weight: 400;
    text-decoration: line-through;
    font-size: 12px;
    line-height: 100%;
    color: var(--grey-60);
  }

  .salePrice {
    color: var(--orange-60);
    font-size: 17px;
    line-height: 17px;
    display: block;
    padding-bottom: 2px;
  }

  .listPrice {
    color: var(--grey-60);
    font-size: 17px;
    font-style: normal;
    line-height: 100%;
    text-transform: uppercase;
    padding-bottom: 2px;
    height: 17px;
  }
}
