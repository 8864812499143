.PrimaryMediaBannerCta {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border: none;
  font-family: var(--founders-cond);
  font-size: var(--text-18);
  font-weight: 600;
  line-height: var(--text-18);

  .PrimaryMediaBannerCta {
    height: inherit;
    width: inherit;
  }
}

.LightPrimaryMediaBannerCta {
  background-color: #f2f2f2;
  color: var(--black);

  &:hover {
    background-color: #ebebeb;
  }

  &:active {
    background-color: #ccc;
  }
}

.DarkPrimaryMediaBannerCta {
  background-color: rgba(42, 42, 42);
  color: var(--white);

  &:hover {
    background-color: rgba(42, 42, 42, 0.85);
  }

  &:active {
    background-color: rgba(42, 42, 42, 0.7);
  }
}
