@use 'assets/styles/utils/helper' as h;
$block: sticky-pip;

.#{$block} {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__close {
    display: none;
    position: absolute;
    top: h.rem(8px);
    right: h.rem(8px);

    &-button {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;

      &::before {
        font-size: h.rem(14px);
        color: var(--white);
      }
    }

    &-label {
      display: none;
    }
  }
}

.#{$block}--stuck {
  --main-nav-height: 60px;

  body.select &,
  body.noticias & {
    --main-nav-height: 80px;
  }

  @mixin topRuleset {
    bottom: auto;
    top: calc(var(--main-nav-height) + var(--election-nav-height, 0px));
  }

  @mixin bottomRuleset {
    bottom: h.rem(20px);
    top: auto;

    @include h.breakpoint-m {
      bottom: h.rem(40px);

      // Account for potentially displayed, undetectible, 'smart app' banner on ios.
      @supports (-webkit-overflow-scrolling: touch) {
        bottom: h.rem(110px);
      }
    }
  }

  @include bottomRuleset(); // Default vertical alignment
  position: fixed;
  z-index: h.get-z-index('sticky-video-parent');
  right: 0;
  left: auto;
  padding-right: h.rem(30px);
  background-color: var(--black);
  width: h.rem(282px);
  height: h.rem(147px);

  @include h.breakpoint-m {
    width: h.rem(393px);
    height: h.rem(205px);
  }

  @include h.breakpoint-l {
    width: h.rem(362px);
    height: h.rem(188px);
  }

  @include h.breakpoint-x {
    width: h.rem(400px);
    height: h.rem(208px);
  }

  &.#{$block}--mobileBottom {
    @include bottomRuleset();
  }

  &.#{$block}--mobileTop {
    @include topRuleset();
  }

  @include h.breakpoint-l {
    &.#{$block}--desktopBottom {
      @include bottomRuleset();
    }

    &.#{$block}--desktopTop {
      @include topRuleset();
    }
  }

  .#{$block}__close {
    display: block;
  }

  // Safari-specific overrides
  @supports (-webkit-overflow-scrolling: touch) {
    overflow-anchor: none; // Disable scroll anchoring
    overflow: visible !important; // Ensure overflow is visible
  }
}
