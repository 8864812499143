@use 'assets/styles/utils/helper' as h;

.notchedHorizontalRule {
  border-top: 1px solid var(--grey-40);
  color: var(--default-color);
  position: relative;

  &::before {
    background-color: currentcolor;
    content: '';
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
  }
}
