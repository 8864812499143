@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.bg-background__gradient {
  background-color: var(--bg-gradient-background-color);
  background-blend-mode: var(--bg-gradient-background-blend-mode);
  background-image: var(--bg-gradient-background-image);
}

.bg-background__wrapper {
  position: relative;
}

.bg-background__picture {
  .bg-use-background & {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    transform-origin: 30% 50%;
  }

  &.bg-background__animate {
    animation: zoomIn 15s ease-in-out infinite alternate;
  }
}

.bg-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: var(--bg-background--height);
}

.bg-background__color {
  background: var(--bg-background-color);
}

@keyframes zoomIn {
  0% { transform: scale(1); }
  100% { transform: scale(1.2); }
}

body.select {
  .lead-type--waffle .bg-background__color {
    background: linear-gradient(135.61deg, var(--blue-60) 2.9%, var(--blue-70) 87.53%);
  }

  .lead-type--waffle .product-waffle .bg-background__color {
    background: var(--white);
  }
}
