@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.package-title {
  background: var(--package-title-background);

  &.package-title--alternate-display-format {
    background: var(--package-title-background--alternate);
  }
}

.package-title__container {
  display: var(--package-title--container--display);
  flex-direction: column;
  align-items: baseline;
  padding: var(--package-title--container--padding);

  &.package-title--with-description {
    padding-bottom: var(--package-title--container-description--padding-bottom);
  }

  @include h.breakpoint-m {
    flex-flow: row wrap;
  }
}

.package-title__description {
  line-height: var(--package-title-description-lineheight);
  margin: var(--package-title-description-margin);
  font-family: var(--package-title-description-fontfamily);
  font-size: var(--package-title-description-font-size);
  color: var(--package-title-description-color);

  .package-title.package-title--alternate-display-format & {
    color: var(--package-title-description-color--alternate);
  }

  @include h.breakpoint-m {
    width: 100%;
  }
}

.package-title__title-text {
  line-height: var(--package-title-text-line-height);
  font-family: var(--package-title-text-font-family);
  font-size: var(--package-title-text-font-size);
  position: relative;
  border-color: inherit;
  font-style: var(--package-title-title-text-font-style);
  font-weight: var(--package-title-title-text-font-weight);
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--package-title-title-text-color);
  background-color: var(--package-title-title-text-background);

  .related-articles-container & {
    text-transform: capitalize;
  }
}

body.today {
  .package-title__title-text {
    @include h.title-underline(
      var(--accent-blue),
      var(--package-title-text-font-size),
      var(--package-title-text-line-height)
    );
  }

  .package-title__title-text--green::after {
    background-color: var(--accent-green);
  }

  .package-title__title-text--yellow::after {
    background-color: var(--accent-yellow);
  }
}

.telemundo,
.entretenimiento,
.shows {
  .package-title.package-title--alternate-display-format .package-title__title-text {
    color: var(--package-title-title-text-color--alternate);
    background-color: var(--package-title-title-text-background--alternate);
  }

  .package-title__title-text::before {
    content: '';
    display: var(--package-title-title-before-display);
    position: absolute;
    border-color: var(--package-title-title-text-line-color);
    border-top-width: 1px;
    border-top-style: solid;
    top: 0.5em; // approximately half a line
    right: 100%;
    width: 134px;
    margin-right: 12px;
  }

  .package-title.package-title--alternate-display-format .package-title__title-text::before {
    border-color: var(--white);
  }
}

.package-title__see-all-link {
  @include h.responsive-variations(
    font-size,
    t.$package-title-see-all-link-font-size
  );
  font-family: var(--package-title-see-all-link-font);
  font-weight: var(--package-title-see-all-link-font-weight);
  font-style: normal;
  color: var(--package-title-see-all-link-color);
  text-transform: uppercase;
  border-bottom: var(--package-title-see-all-link-underline);
  border-bottom-color: var(--package-title-see-all-link-underline-color);
  order: 1;
  padding-bottom: var(--package-title-see-all-link-bottom-spacing);
  margin-top: h.rem(12px);

  @include h.breakpoint-m {
    order: 0;
    margin-top: 0;
    margin-left: h.rem(12px);
  }
}

.package-title__see-all-link--alt-color {
  color: var(--package-title-see-all-link-alt-color);
}
