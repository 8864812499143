@use 'assets/styles/utils/helper' as h;

.card-overlay {
  --card-overlay--border-colors: var(--default-gradient);
  --card-overlay--border-width: 10px;
  --card-overlay--transform: translateY(100%);

  body.news & {
    --card-overlay--border-colors: (var(--blue-40), var(--blue-40));
  }

  body.today & {
    --card-overlay--border-colors: (var(--gradient-value-2), var(--gradient-value-1));
    --card-overlay--border-width: 0;
    --card-overlay--transform: translateY(-100%);

    @include h.breakpoint-m {
      --card-overlay--border-width: 10px;
    }
  }

  body.today {
    --card-overlay--border-width: 0;
    --card-overlay--font-family: var(--secondary-font);
  }
}
