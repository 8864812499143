@use 'assets/styles/utils/helper' as h;

:root {
  --unibrow--default--color: var(--primary-color);
  --unibrow--default--font-size: #{h.rem(12px)};
  --unibrow--default--font-family: var(--secondary-font);
  --unibrow--default--font-weight: normal;
  --unibrow--default--line-height: var(--leading-150);
  --unibrow--default--margin: 0 0 #{h.rem(3px)};
  --unibrow--default--text-transform: uppercase;
}

body.today {
  --unibrow--default--color: var(--grey-70);
  --unibrow--default--font-weight: bold;

  .unibrow--breaking-news {
    --unibrow--default--color: var(--primary-60);
  }
}
