@use 'assets/styles/utils/helper' as h;

.container {
  display: flex;
  margin-top: h.rem(12px);
  align-items: center;

  :global(.content-link-card) & {
    margin-top: h.rem(-7px);
    margin-bottom: h.rem(7px);
  }

  :global(.pkg.bacon) & {
    margin-top: h.rem(8px);
  }

  :global(.info-tile__key),
  :global(.info-tile__value) {
    font-size: h.rem(12px);
  }

  :global(.info-tile) {
    white-space: nowrap;
    margin-right: h.rem(5px);
    align-items: center;

    &::before {
      content: '|';
      color: var(--grey-50);
      padding-right: h.rem(5px);
    }

    &:first-child {
      &::before {
        content: none;
      }
    }

    &:last-child {
      :global(.pkg.featureLead) & {
        @include h.breakpoint-m-only {
          display: none;
        }
      }
    }
  }
}
