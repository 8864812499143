@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.imageCredit {
  position: absolute;
  bottom: var(--image-credit--bottom);
  right: var(--image-credit--right);
  z-index: 2;
  word-spacing: var(--image-credit--word-spacing);
  letter-spacing: var(--image-credit--letter-spacing);
  color: var(--image-credit--color);
  text-align: right;
  line-height: var(--image-credit--line-height);
  font-size: var(--image-credit--font-size);
  font-family: var(--image-credit--font-family);
  pointer-events: none;
}
