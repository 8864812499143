@use 'assets/styles/utils/helper' as h;

$caption--font-size: h.rem(14px) !default;
$caption--font-style: normal !default;
$caption--font-weight: normal !default;
$caption--letter-spacing: 0 !default;

$caption--source--font-size: h.rem(12px) !default;

:root {
  --caption--color: var(--grey-70);
  --caption--font-family: var(--default-text-font-family);
  --caption--line-height: 1.5;
  --caption--container--margin: 0;
  --caption--source--color: var(--grey-60);
  --caption--source--font-family: var(--founders-mono);
  --caption--source--letter-spacing: -0.04em;
  --caption--source--margin: 0 0 0 #{h.rem(12px)};
  --caption--source--font-weight: normal;
  --caption--source--word-spacing: -0.1em;
  --caption--before--border-bottom-color: var(--primary-color);
}

body.select {
  --caption--color: #2a2a2a;
  --caption--line-height: 1.2;
  --caption--source--color: #555;
  --caption--source--font-family: var(--lato);
  --caption--source--letter-spacing: 0;
  --caption--source--margin: 0 0 0 #{h.rem(8px)};
  --caption--source--word-spacing: 0;
  --caption--before--border-bottom-color: var(--blue-60);
}

body.globalcitizen {
  --caption--before--border-bottom-color: var(--red-40);
}

body.today {
  --caption--font-family: var(--tertiary-font);
  --caption--container--margin: 0 #{h.rem(5px)} 0 0;
  --caption--source--font-family: var(--secondary-font);
  --caption--source--font-weight: bold;
  --caption--source--margin: 0;
  --caption--source--word-spacing: 0;
}
