@use 'assets/styles/utils/helper' as h;

.bg-background__wrapper {
  --bg-background-color: var(--default-color);
  --bg-background--height: auto;

  body.today & {
    --bg-background-color: linear-gradient(90deg, #ff503c 0, #720a72 100%);
  }

  body.telemundo & {
    --bg-background-color: var(--blue-70);
  }

  body.noticias & {
    --bg-background-color: var(--blue-60);
  }

  body.deportes & {
    --bg-background-color: var(--blue-60);
  }

  body.entretenimiento & {
    --bg-background-color: var(--blue-70);
  }

  body.news & {
    --bg-background-color: var(--blue-70);
  }

  body.select & {
    --bg-background-color: transparent;
  }

  body.eonline & {
    --bg-background-color: linear-gradient(125deg, rgba(var(--teal-60), .7) 20%, rgba(var(--magenta-60), .7) 75%);
  }

  body.msnbc & {
    --bg-background-color: #{h.$msnbc-default-color};
  }

  body.today .collection-lead & {
    --bg-background--height: #{h.rem(130px)};
    --bg-background-color: none;

    @include h.breakpoint-l {
      --bg-background--height: #{h.rem(150px)};
    }
  }

  body.select .product-waffle .collection-lead & {
    --bg-background--height: #{h.rem(130px)};
    --bg-background-color: none;

    @include h.breakpoint-l {
      --bg-background--height: #{h.rem(150px)};
    }
  }
}

.bg-background__gradient {
  body.news & {
    --bg-gradient-background-color: rgba(var(--blue-70-rgb), 0.8);
  }

  body.noticias & {
    --bg-gradient-background-color: rgba(var(--blue-60-rgb), 0.8);
  }

  body.mach & {
    --bg-gradient-background-color: rgba(var(--purple-50-rgb), 0.8);
  }

  body.better & {
    --bg-gradient-background-color: rgba(var(--teal-30-rgb), 0.8);
  }

  body.globalcitizen & {
    --bg-gradient-background-color: rgba(var(--teal-40-rgb), 0.8);
  }

  body.msnbc & {
    --bg-gradient-background-color: rgba(var(--blue-40-rgb), 0.8);
  }

  body.think & {
    --bg-gradient-background-color: rgba(var(--red-40-rgb), 0.8);
  }

  body.today & {
    --bg-gradient-background-color: transparent;
    --bg-gradient-background-blend-mode: none;
    --bg-gradient-background-image: none;
  }

  body.telemundo &,
  body.entretenimiento & {
    --bg-gradient-background-color: rgba(15, 15, 25, .6);
  }
}
