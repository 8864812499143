@use 'assets/styles/utils/helper' as h;
@use 'sass:math';

.baconCardsWidthByOne {
  overflow: hidden;
  width: 100%;

  :global(body.today) & {
    display: flex;
    flex-direction: column;
  }

  :global(body.today .article-body) & {
    line-height: 1;
  }

  .unibrow {
    transition: transform linear 150ms;
  }

  &Icon {
    align-items: center;
    color: var(--white);
    display: inline-flex;
    font-size: 1rem;
    height: 100%;
    transform: scale(0.375);
    transform-origin: center;
    transition: transform linear 150ms;

    &Container {
      display: block;
      margin-top: -2rem;
      padding: 0 h.rem(8px);
      position: relative;
      width: 30px;
      z-index: 2;

      :global(body.today) & {
        display: none;
      }
    }

    &Background {
      background: var(--default-color);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform-origin: bottom right;
      transition: transform linear 150ms;
      z-index: -1;

      :global(body.globalcitizen) & {
        background: var(--red-40);
      }
    }
  }

  .baconCardsWidthByOneTeasePictureLinkWrapper {
    position: relative;
  }

  .baconCardsWidthByOneTeasePictureLink svg {
    position: absolute;
    bottom: 0;
    left: 0;

    :global(body.today) & {
      top: 0;
      right: 0;
      left: auto;
    }
  }

  // gallery & shop icons need special handling due to size differences
  .icon-gallery,
  .icon-shop {
    transform: scale(0.5) translateX(-10px);
  }

  &UnibrowSectionName {
    color: var(--default-color);
    display: inline-block;
    font-family: var(--founders-mono);
    letter-spacing: unset;
    text-transform: uppercase;

    :global(body.select) & {
      color: var(--blue-60);
      font-family: var(--lato);
      letter-spacing: 0;
    }

    :global(body.think) & {
      color: var(--red-40);
    }

    :global(body.today) & {
      color: inherit;
      font-family: var(--secondary-font);
      font-weight: var(--bold);
      letter-spacing: 0;
      margin-top: h.rem(22px);

      & a:hover {
        color: var(--secondary-color);
      }
    }
  }

  .baconCardsWidthByOneTeasePictureLink:not(.baconCardsWidthByOneTeasePictureLinkDeepLink) {
    display: block;
    position: relative;

    &:hover {
      .baconCardsWidthByOneIcon {
        transform: translate(-2.2rem, -0.5rem) scale(1);
      }

      .baconCardsWidthByOneIconBackground {
        transform:
          scaleX(h.strip-unit(math.div(100px, 30px)))
          scaleY(h.strip-unit(math.div(50px, 32px)));
      }

      // gallery & shop icons need special handling due to size differences
      .baconCardsWidthByOneIcon.icon-gallery,
      .baconCardsWidthByOneIcon.icon-shop {
        transform: translate(-#{(96px + 30px * 0.5) * 0.5}, -1rem) scale(1);

        & + .baconCardsWidthByOneIconBackground {
          transform:
            scaleX(h.strip-unit(math.div(128px, 30px)))
            scaleY(h.strip-unit(math.div(64px, 32px)));
        }
      }
    }
  }

  &TeaseCategory {
    display: flex;
    position: relative;

    :global(body.today) & {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &Eyebrow {
    background-color: var(--white);
    color: var(--grey-60);
    font-family: var(--founders-mono);
    font-size: h.rem(12px);
    font-weight: 100;
    height: h.rem(32px);
    justify-content: center;
    line-height: var(--leading-100);
    margin: h.rem(-32px) 0 0;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;

    :global(body.today) & {
      height: h.rem(24px);
      margin: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: var(--white);
      font-family: var(--secondary-font);
      font-weight: var(--normal);
      padding: 6px 8px;
      min-width: 48px;
    }

    &Slideshow {
      width: 96px;
    }

    &Video {
      min-width: 68px;
    }

    .baconCardsWidthByOneEyebrowDeepLinkVideo {
      background-color: var(--white);
      color: var(--default-color);
      display: flex;
      float: left;
      justify-content: flex-start;
      margin: h.rem(-32px) 0 0;
      min-width: h.rem(130px);
      padding-left: h.rem(16);
      padding-right: h.rem(16);
      padding-top: h.rem(16);
      position: relative;
      width: auto;

      :global(body.entretenimiento) &,
      :global(body.shows) &,
      :global(body.telemundo) & {
        color: var(--red-50);
      }
    }
  }

  &FontStyle {
    font-size: h.rem(12px);
    font-weight: var(--normal);
    margin-bottom: 0;
    margin-top: h.rem(8px);
  }

  &TimestampStyle {
    color: var(--grey-60);
    font-family: var(--publico-txt);

    :global(body.select) & {
      color: #555;
      font-family: var(--lato);
    }

    :global(body.today) & {
      color: var(--grey-70);
      font-family: var(--secondary-font);
    }
  }

  &Header {
    margin-bottom: 0;
    margin-right: h.rem(16px);
    margin-top: h.rem(12px);

    :global(body.today) & {
      order: 1;
      margin-top: h.rem(2px);

      &.noEyebrow {
        margin-top: h.rem(20px);
      }
    }

    :global(body.today .related-recipes) & {
      order: 0;
      margin-top: h.rem(8px);
    }

    :global(body.today .video-page) & {
      margin-right: h.rem(20px);

      @include h.breakpoint-m {
        margin-right: 0;
      }
    }
  }

  &HeaderLink {
    color: var(--grey-70);
    font-family: var(--founders-cond);
    font-size: h.rem(18px);
    font-weight: var(--normal);
    line-height: var(--leading-100);
    margin-bottom: h.rem(4px);
    margin-top: h.rem(16px);

    :global(body.select) & {
      color: var(--grey-100);
      font-family: var(--lato);
      font-size: h.rem(18px);
      font-weight: 900;
      line-height: var(--leading-100);
    }

    :global(body.today) & {
      color: var(--grey-70);
      font-family: var(--secondary-font);
      font-size: h.rem(16px);
      line-height: 1.5;
    }

    :global(body.entretenimiento) &,
    :global(body.shows) &,
    :global(body.telemundo) & {
      color: var(--blue-60);
    }

    :global(body.today .video-page) & {
      color: var(--white);
    }
  }

  &HeaderLinkIcon {
    display: none;

    :global(body.today) & {
      display: inline-block;
    }
  }

  &HeaderDeepLinkEpisode {
    color: var(--default-color);
    font-family: var(--publico-hed);
    font-size: h.rem(16px);
    font-weight: var(--light);
    line-height: var(--leading-125);

    :global(body.shows) &,
    :global(body.telemundo) &,
    :global(body.entretenimiento) & {
      color: var(--red-50);
    }
  }
}

.xByOneDeepLinkSeason {
  text-transform: uppercase;

  &Date {
    color: var(--grey-60);
    font-family: var(--founders-mono);
    font-size: h.rem(12px);
    font-weight: var(--normal);
    margin-bottom: 0;
    margin-top: h.rem(8px);
  }
}
