@use 'assets/styles/utils/helper' as h;

:root {
  --bacon-package-padding: 0 #{h.rem(20px)};
  --bacon-package-background-color: var(--white);
  --bacon-package-button-width: 50px;
  --bacon-package-header-margin: 0 0 #{h.rem(12px)} 0;
  --bacon-package-title-font-family: var(--default-headline-font-family);
  --bacon-package-title-font-style: normal;
  --bacon-package-title-font-size: 1.5rem;
  --bacon-package-title-color: var(--grey-70);
  --bacon-package-title-font-weight: normal;
  --bacon-package-title-line-height: var(--leading-100);
  --bacon-package-title-padding-right: #{h.rem(12px)};
  --bacon-package-descriptionWrapper-font-color: var(--default-text-font-color);
  --bacon-package-descriptionWrapper-font-family: var(--default-text-font-color);
  --bacon-package-descriptionWrapper-font-size: var(--default-text-font-family);
  --bacon-package-descriptionWrapper-line-height: #{h.rem(14px)};
  --bacon-package-description-font-color: var(--default-text-font-color);
  --bacon-package-description-font-family: var(--default-text-font-family);
  --bacon-package-description-font-size: #{h.rem(14px)};
  --bacon-package-description-line-height: var(--leading-150);
  --bacon-package-btn-container-bkg-color: var(--white);
  --bacon-package-btn-icon-color: var(--default-color);
  --bacon-package-btn-icon-hover-color: var(--white);
  --bacon-package-btn-hover-color: var(--default-color);
  --bacon-package-btn-next-width: auto;
  --bacon-package-paging-dots-display: flex;
  --bacon-package-paging-dot-margin: 0 #{h.rem(50px)} 0 auto;
  --bacon-package-paging-dot-border-color: var(--grey-50);
  --bacon-package-paging-dot-color: var(--default-color);
  --bacon-package-active-paging-dot-color: currentcolor;
  --bacon-package-paging-dot-height: 9px;
  --bacon-package-paging-dot-width: 9px;
  --bacon-package-paging-dot-spacing: 5px;
  --bacon-package-paging-dot-align-self: center;
  --bacon-package-header-inner-margin: #{h.rem(12px) 0 0};
  --bacon-package-add-to-bag-btn-font-family: var(--default-text-font-family);
  --bacon-package-scroll-container-scroll-padding: 52px;
  --bacon-package-card-wrapper-spacing: 2px;
  --bacon-package-btn-width: #{h.rem(38px)};
  --bacon-package-btn-next-icon-transform: rotate(270deg);
  --bacon-package-btn-prev-icon-transform: rotate(90deg);
  --bacon-package-link-color: var(--primary-color);
  --bacon-package-link-hover-color: var(--primary-color);
  --bacon-package-link-active-opacity: var(--default-active-opacity);
  --bacon-package-link-underline-width: 1px;
  --bacon-package-link-underline-color: var(--primary-color);
  --bacon-package-link-underline-padding: 2px;

  @include h.breakpoint-s-only {
    --bacon-package-paging-dots-display: none;
  }

  @include h.breakpoint-l {
    --bacon-package-paging-dots-display: flex;
  }
}

:global(body.select) {
  --bacon-package-title-font-size: 20px;
  --bacon-package-title-color: #2a2a2a;
  --bacon-package-title-font-weight: 900;
  --bacon-package-title-line-height: var(--leading-125);
  --bacon-package-btn-icon-color: var(--blue-60);
  --bacon-package-btn-icon-hover-color: var(--blue-60);
  --bacon-package-paging-dot-color: var(--blue-60);
  --bacon-package-descriptionWraper-font-family: var(--default-headline-font-family);
  --bacon-package-descriptionWraper-font-size: 15px;
  --bacon-package-descriptionWraper-line-height: var(--text-24);
  --bacon-package-description-font-family: var(--default-headline-font-family);
  --bacon-package-description-font-size: 15px;
  --bacon-package-description-line-height: var(--text-24);
  --bacon-multiseller-header-height: 48px;
  --bacon-multiseller-description-height: 54px;
  --bacon-multiseller-line-clamp: 3;
  --bacon-package-link-color: var(--blue-60);
  --bacon-package-link-hover-color: var(--blue-60);
  --bacon-package-link-hover-opacity: var(--default-hover-opacity);
  --bacon-package-card-wrapper-h3-hover-opacity: 0.7;
  --bacon-package-card-wrapper-h3-hover-color: inherit;

  @include h.breakpoint-m {
    --bacon-package-descriptionWrapper-font-size: 17px;
    --bacon-package-descriptionWrapper-line-height: 29px;
    --bacon-package-description-font-size: 17px;
    --bacon-package-description-line-height: 29px;
  }

  .inlineArticle {
    --bacon-descriptionWrapper-font-family: var(--lato);
    --bacon-descriptionWrapper-font-size: 15px;
    --bacon-descriptionWrapper-line-height: 29px;
    --bacon-description-font-family: var(--lato);
    --bacon-description-font-size: 15px;
    --bacon-description-line-height: 29px;
    --bacon-disclaimer-font-family: var(--lato);
    --bacon-package-description-line-height: 24px;
    --bacon-multiseller-header-font-weight: normal;
    --bacon-package-card-wrapper-width: 220px;
    --bacon-multiseller-header-font-size: 16px;
    --bacon-multiseller-font-size: 14px;
    --bacon-multiseller-seller-font-size: 14px;

    @include h.breakpoint-m {
      --bacon-descriptionWrapper-font-size: 17px;
      --bacon-description-font-size: 17px;
    }
  }
}

:global(body.shows),
:global(body.entretenimiento),
:global(body.telemundo) {
  --bacon-package-title-color: var(--blue-60);
}

:global(body.shows),
:global(body.noticias),
:global(body.entretenimiento),
:global(body.telemundo),
:global(body.deportes) {
  --bacon-package-descriptionWrapper-font-color: var(--grey-60);
  --bacon-package-descriptionWrapper-font-size: #{h.rem(14px)};
  --bacon-package-descriptionWrapper-line-height: #{h.rem(21px)};
  --bacon-package-description-font-color: var(--grey-60);
  --bacon-package-description-font-size: #{h.rem(14px)};
  --bacon-package-description-line-height: #{h.rem(21px)};
}

:global(body.today) {
  --bacon-package-background-color: transparent;
  --bacon-package-title-font-style: italic;
  --bacon-package-title-color: var(--purple-70);
  --bacon-package-title-font-weight: 200;
  --bacon-package-descriptionWrapper-font-family: var(--tertiary-font);
  --bacon-package-descriptionWrapper-line-height: 1.5em;
  --bacon-package-description-font-family: var(--tertiary-font);
  --bacon-package-description-line-height: 1.5;
  --bacon-package-add-to-bag-btn-font-family: var(--secondary-font);
  --bacon-package-link-color: var(--grey-70);
  --bacon-package-link-active-opacity: 1;
  --bacon-package-link-hover-opacity: 1;
  --bacon-package-link-underline-width: 2px;
  --bacon-package-link-underline-color: var(--accent-orange);
  --bacon-package-link-underline-padding: 2px;
  --bacon-package-descriptionWrapper-font-color: var(--grey-60);
  --bacon-package-description-font-color: var(--grey-70);
  --bacon-package-description-font-size: #{h.rem(18px)};
  --bacon-package-paging-dots-margin-top: #{h.rem(10px)};
  --bacon-package-btn-container-bkg-color: var(--white);
  --bacon-package-btn-icon-color: var(--grey-70);
  --bacon-package-paging-dot-color: var(--grey-70);
  --bacon-package-header-inner-margin: #{h.rem(12px) 0 0};
  --bacon-package-btn-hover-color: var(--accent-orange);
  --bacon-package-btn-next-width: 38px;
  --bacon-package-paging-dot-align-self: flex-end;
  --bacon-package-paging-dot-disclaimer-modal-margin-top: #{h.rem(-10px)};
  --bacon-package-paging-dot-disclaimer-modal-margin-bottom: #{h.rem(10px)};
  --bacon-package-canonical-video-active-dot--color: var(--orange-30);
  --bacon-package-card-wrapper-h3-hover-opacity: 1;
  --bacon-package-card-wrapper-h3-hover-color: var(--accent-orange);

  @include h.breakpoint-m {
    --bacon-package-title-font-size: 2rem;
    --bacon-package-padding: 0;
    --bacon-package-descriptionWrapper-font-size: #{h.rem(16px)};
    --bacon-package-description-font-size: #{h.rem(16px)};
  }

  .default,
  .twoOneSmall,
  .twoOneLarge,
  .twoOneLargeItems,
  .oneOne,
  .twoThreePortrait {
    --bacon-package-card-wrapper-spacing: 20px;
  }

  .inlineArticle {
    --bacon-package-card-wrapper-width: 220px;
    --bacon-multiseller-header-font-size: 16px;
    --bacon-multiseller-font-size: 14px;
    --bacon-multiseller-seller-font-size: 14px;
    --bacon-multiseller-header-height: 60px;
    --bacon-multiseller-description-height: 63px;
    --bacon-multiseller-line-clamp: 3;
  }

  :global(.video-page) {
    --bacon-package-background-color: var(--black);
    --bacon-package-header-margin: 0 0 #{h.rem(14px)} 0;
    --bacon-package-title-font-size: #{h.rem(24px)};
    --bacon-package-title-font-family: var(--primary-font);
    --bacon-package-title-font-style: normal;
    --bacon-package-title-font-weight: var(--normal);
    --bacon-package-title-line-height: var(--leading-150);
    --bacon-package-title-color: var(--white);
    --bacon-package-paging-dots-display: none;
    --bacon-package-paging-dot-border-color: var(--grey-60);
    --bacon-package-paging-dot-height: 8px;
    --bacon-package-paging-dot-width: 8px;
    --bacon-package-paging-dot-spacing: 4px;
    --bacon-package-scroll-container-scroll-padding: 0;
    --bacon-package-card-wrapper-spacing: 20px;
    --bacon-package-button-width: #{h.rem(50px)};
    --bacon-package-btn-width: #{h.rem(40px)};
    --bacon-package-paging-dot-margin: 0 #{h.rem(50px)} 0 auto;
    --bacon-package-btn-next-icon-transform: rotate(180deg);
    --bacon-package-btn-prev-icon-transform: rotate(0deg);
    --bacon-package-btn-icon-hover-color: var(--black);

    @include h.breakpoint-m {
      --bacon-package-paging-dots-display: none;
      --bacon-package-title-font-size: #{h.rem(28px)};
      --bacon-package-header-margin: 0 0 #{h.rem(20px)} 0;
      --bacon-package-scroll-container-scroll-padding: 50px;
    }

    @include h.breakpoint-l {
      --bacon-package-paging-dots-display: flex;
    }
  }

  .twoOneLargeItemsBacon {
    --bacon-package-card-wrapper-spacing: #{h.rem(16px)};
  }
}

:global(body.today .article-body) {
  --bacon-package-padding: 0;

  @include h.breakpoint-s-only {
    --bacon-package-padding: 0 #{h.rem(20px)};
  }
}
