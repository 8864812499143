@use 'assets/styles/utils/helper' as h;

.disclaimerModal {
  --disclaimer-modal--card--background: rgba(var(--white-rgb), 0.95);
  --disclaimer-modal--card--small--padding: #{h.rem(32px 24px)};
  --disclaimer-modal--card--small--right: -1.25rem;
  --disclaimer-modal--card--small--width: #{h.rem(268px)};
  --disclaimer-modal--card-z-index: 2;
  --disclaimer-modal--card--border-radius: #{h.rem(3px)};
  --disclaimer-modal--text--font-family: var(--default-text-font-family);
  --disclaimer-modal--text--font-size: #{h.rem(12px)};
  --disclaimer--modal--toggle--font-family: inherit;
  --disclaimer--modal--toggle--font-style: inherit;
  --disclaimer--modal--button--line--border-bottom: 2px solid var(--grey-40);
  --disclaimer--modal--button--line--margin-top: #{h.rem(12px)};
  --disclaimer--modal--toggle--svg-color: inherit;
  --disclaimer--modal--toggle--font-size: 100%;
  --disclaimer--modal--toggle--font-weight: normal;

  :global(body.select) & {
    --disclaimer-modal--card--background: rgba(var(--white-rgb), 0.9);
    --disclaimer-modal--card--small--padding: #{h.rem(32px 24px 20px)};
    --disclaimer-modal--card--small--width: #{h.rem(342px)};
  }

  :global(body.today) & {
    --disclaimer-modal--card-z-index: 200;
    --disclaimer-text-font-family: var(--tertiary-font);
    --disclaimer-text-font-size: 14px;
    --disclaimer-text-line-height: 21px;
    --disclaimer-link-border-width: 2px;
    --disclaimer-link-border-color: #ffb186;
    --disclaimer--modal--toggle--font-family: var(--secondary-font);
    --disclaimer--modal--toggle--font-style: normal;
    --disclaimer--modal--button--line--border-bottom: none;
    --disclaimer--modal--button--line--margin-top: 0;
    --disclaimer--modal--toggle--svg-color: var(--default-color);
    --disclaimer--modal--toggle--font-size: #{h.rem(20px)};
    --disclaimer--modal--toggle--font-weight: bold;
    --disclaimer-modal--card--background: var(--accent-orange-light);
    --disclaimer-modal--card--border-radius: 0;
    --disclaimer-modal--text--font-family: var(--secondary-font);
    --disclaimer-modal--text--font-size: #{h.rem(14px)};
  }
}
