@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.baconCardsWidthByOne {
  font-family: var(--bacon-multiseller-font-family);
  padding-left: var(--bacon-multiseller-padding, 12px);
  padding-right: var(--bacon-multiseller-padding, 12px);
  position: relative;

  &::after {
    background-color: var(--bacon-multiseller-divider-color);
    content: '';
    height: 487px;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.2px;
  }

  picture {
    margin-bottom: var(--bacon-multiseller-margin-bottom, 16px);
  }
}

:global(.lastItem) {
  .baconCardsWidthByOne::after {
    content: none;
  }
}

.baconCardsWidthByOneTeasePictureLinkWrapper {
  position: relative;
}

.baconCardsSingleSeller {
  padding-bottom: 0;
}

.baconCardsWidthByOneHeader {
  font-family: var(--bacon-multiseller-header-font-family);
  font-style: normal;
  font-weight: var(--bacon-multiseller-header-font-weight);
  font-size: var(--bacon-multiseller-header-font-size);
  line-height: var(--bacon-multiseller-header-line-height);
  color: var(--bacon-multiseller-header-color);
  margin: 0 0 8px;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-line-clamp: var(--bacon-multiseller-line-clamp, 2);
  -webkit-box-orient: vertical;
  height: var(--bacon-multiseller-header-height);
  overflow: hidden;
}

.description {
  font-family: var(--bacon-multiseller-description-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--bacon-multiseller-description-font-size);
  line-height: 150%;
  color: var(--bacon-multiseller-description-color);
  margin: var(--bacon-multiseller-description-margin);
  margin-bottom: 16px;
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-line-clamp: var(--bacon-multiseller-description-line-clamp);
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: var(--bacon-multiseller-description-height);
}

.productSellerWrapper {
  align-items: center;
  border-bottom: 1px var(--bacon-multiseller-divider-color) solid;
  display: flex;
  justify-content: space-between;
  height: 40px;

  &:hover,
  &:active {
    color: var(--bacon-multiseller-price-hover-color);
  }

  &:hover {
    opacity: var(--bacon-multiseller-price-hover-opacity);
  }

  &:active {
    opacity: var(--bacon-multiseller-price-active-opacity);
  }

  .outOfStock {
    font-family: var(--bacon-multiseller-font-family);
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.04rem;
    text-transform: uppercase;
    color: var(--grey-60);
  }

  .seller {
    font-family: var(--bacon-multiseller-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: var(--bacon-multiseller-seller-font-size);
    line-height: 120%;
    text-align: right;
    letter-spacing: var(--bacon-multiseller-seller-letter-spacing);
    text-transform: uppercase;
    text-decoration: underline;
    text-decoration-color: var(--bacon-multiseller-seller-text-decoration-color);
    text-decoration-thickness: var(--bacon-multiseller-seller-thickness);
    text-underline-offset: var(--bacon-multiseller-seller-offset);
    max-width: 145px;
    margin: var(--bacon-multiseller-seller-margin);
    padding: var(--bacon-multiseller-seller-padding);
  }

  .priceWrapper {
    p {
      margin: 0;
    }
  }

  .price {
    color: var(--bacon-multiseller-price-color);
    font-family: var(--bacon-multiseller-font-family);
    font-size: var(--bacon-multiseller-font-size, 17px);
    font-style: normal;
    font-weight: var(--bacon-multiseller-price-font-weight);
    letter-spacing: -0.04rem;
    line-height: 100%;
    text-transform: uppercase;
  }

  .newPrice {
    color: var(--bacon-multiseller-new-price-color);
    padding-bottom: var(--bacon-multiseller-new-price-padding-bottom);
  }

  .previousPrice {
    color: var(--grey-60);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.04rem;
    line-height: 100%;
    margin-top: -5px;
    text-decoration-line: line-through;
    text-transform: uppercase;
  }

  .baconCardsWidthByOne a:last-child & {
    border-bottom: none;
  }
}
