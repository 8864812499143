@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.disclaimerModal {
  display: inline-block;
  position: relative;

  :global(body.select) & {
    padding-right: 20px;
  }
}

.toggleBtn {
  align-items: center;
  background: transparent;
  border: none;
  color: inherit;
  display: flex;
  flex-flow: row nowrap;
  font-family: var(--disclaimer--modal--toggle--font-family);
  font-size: var(--disclaimer--modal--toggle--font-size);
  font-style: var(--disclaimer--modal--toggle--font-style);
  font-weight: var(--disclaimer--modal--toggle--font-weight);
  justify-content: center;
  padding: 0;
  width: h.rem(230px);

  svg {
    height: h.rem(7px);
    margin-left: h.rem(8px);
    transform: rotate(0deg);
    transition: transform .2s linear;
    width: h.rem(10px);
    color: var(--disclaimer--modal--toggle--svg-color);

    .open & {
      transform: rotate(-180deg);
    }
  }
}

.line {
  border-bottom: var(--disclaimer--modal--button--line--border-bottom);
  content: ' ';
  display: block;
  margin-top: var(--disclaimer--modal--button--line--margin-top);

  .open & {
    opacity: 0;
  }
}

.infoBtn {
  background: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: 0;
  outline: none;
  padding: 0;
  vertical-align: middle;

  span {
    font-size: 13px;
    margin-left: var(--space-4);

    &:focus > & {
      color: var(--grey-70);
    }
  }
}

.card {
  background: var(--disclaimer-modal--card--background);
  border-radius: var(--disclaimer-modal--card--border-radius);
  color: var(--grey-60);
  left: 50%;
  margin-left: -50vw;
  padding: h.rem(32px);
  position: absolute;
  right: 0;
  text-align: left;
  width: 100vw;
  z-index: var(--disclaimer-modal--card-z-index);

  @include h.breakpoint-m {
    left: 50%;
    margin-left: h.rem(-236px); // 50% width
    width: h.rem(471px);
  }

  button {
    right: h.rem(10px);
    top: h.rem(10px);

    &:focus {
      outline: none;
    }
  }

  &.small {
    border: 1px solid var(--grey-40);
    color: var(--grey-70);
    left: 0;
    margin: h.rem(8px) 0 0 0;
    padding: var(--disclaimer-modal--card--small--padding);
    right: var(--disclaimer-modal--card--small--right);
    width: var(--disclaimer-modal--card--small--width);
  }
}

.text {
  font-family: var(--disclaimer-modal--text--font-family);
  font-size: var(--disclaimer-modal--text--font-size);
  font-style: normal;
  line-height: var(--disclaimer-text-line-height, h.rem(18px));
  margin: 0;
  padding: 0;

  a {
    border-bottom: var(--disclaimer-link-border-width, 1px) solid var(--disclaimer-link-border-color, var(--default-color));
  }
}
