@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

/* stylelint-disable no-descending-specificity */
:global {
  .baconScrollContainer {
    padding-left: var(--outer-gutter);
  }

  .baconPrevBtnContainer {
    width: var(--outer-gutter);
  }
}

%baconPageBtn {
  background: transparent;
  border-color: var(--grey-40);
  border-width: 0;
  color: var(--bacon-package-btn-icon-color);
  display: block;
  height: 100%;
  outline: none;
  width: var(--bacon-package-btn-width);

  &:hover {
    color: var(--bacon-package-btn-icon-hover-color);
    background-color: var(--bacon-package-btn-hover-color);
  }

  .baconPageBtnIcon {
    display: block;
    font-size: h.rem(32px);
    line-height: 1;
  }
}

.baconPageBtnNext {
  @extend %baconPageBtn !optional;
  width: var(--bacon-package-btn-next-width);
  border-left-style: solid;
  border-left-width: 1px;

  .baconPageBtnIcon {
    transform: var(--bacon-package-btn-next-icon-transform);
  }
}

.baconPageBtnPrev {
  @extend %baconPageBtn !optional;
  border-right-style: solid;
  border-right-width: 1px;

  .baconPageBtnIcon {
    transform: var(--bacon-package-btn-prev-icon-transform);
  }
}

.baconPageBtnNext,
.baconPageBtnPrev {
  :global(body.today .video-page) & {
    border-color: var(--grey-50);

    .baconPageBtnIcon {
      &::before {
        content: '';
        background-color: var(--primary-color);
        mask-image: url('~assets/icons/back.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        display: block;
        width: 14px;
        height: 24px;
        margin: 0 auto;
      }
    }

    &:hover .baconPageBtnIcon::before {
      background-color: #102039;
    }
  }
}

.baconPackage {
  background-color: var(--bacon-package-background-color);
  border-color: var(--grey-40);
  overflow: hidden;
  position: relative;
  padding: var(--bacon-package-padding);
  width: 100%;

  &.inlineArticle {
    padding: 0;

    --bacon-package-scroll-container-padding: 0 20px;

    .descriptionWrapper {
      font-size: 12px;
      width: 100%;

      :global(.icon-info) {
        font-size: 11px;
      }
    }
  }

  :global(body.today) &.stripe {
    padding-right: 0;

    @include h.slanted-background;
  }

  // removes slanted background from bacon with shopping card on mid articles
  :global(body.today.articlePage .no-slanted-bg) & {
    &::before {
      content: normal;
    }
  }

  :global(body.today.articlePage) &,
  :global(body.today.packageEmbedPage) & {
    @include h.breakpoint-s-only {
      margin-bottom: 0;
    }

    @include h.breakpoint-l {
      margin-bottom: 0;
    }
  }

  :global(body.today .video-page) & {
    margin: h.rem(32px) 0;

    @include h.responsive-variations(padding, (
      s: 0 0 0 h.rem(20px),
      m: 0 h.rem(30px),
      l: 0 h.rem(40px),
      x: 0 h.rem(100px),
      xx: 0 h.rem(200px)
    ));

    &:first-of-type {
      margin-top: h.rem(48px);
    }
  }

  :global(body.today .layout-grid-item) & {
    &:first-child {
      padding-top: 20px;
    }
  }

  @include h.breakpoint-s-only {
    padding-right: 0;
  }
}

.baconHeaderTopBorder {
  padding-top: h.rem(24px);

  :global(.select) &,
  :global(.shows) &,
  :global(.entretenimiento) &,
  :global(.telemundo) &,
  :global(.today) & {
    display: none;
  }
}

.baconHeader {
  margin: var(--bacon-package-header-margin);

  @include h.breakpoint-l {
    display: flex;

    &Full {
      display: block;
    }
  }

  &.flexColumn {
    flex-direction: column;
  }
}

.baconHeaderHeadline {
  @include h.map-to-responsive-rules((
    background-image: none,
    background-position: center,
    background-repeat: unset,
    background-size: unset,
    flex: 1 1 auto,
    margin: (s: 0 20px 0 0, m: 0),
    overflow: hidden,
  ));

  :global(.select) & {
    background-image: var(--default-background-image);
    background-repeat: repeat-x;
    background-size: 18px;
    background-position: 0 5px;
  }

  .baconHeaderHeadlineBtn {
    all: unset;

    @include h.breakpoint-s-only {
      :global(.select) & {
        width: 100%;
      }
    }
  }
}

.allItemsToCart {
  display: block;
  position: relative;
  text-transform: uppercase;
  height: 47px;
  width: 268px;
  font-family: var(--bacon-package-add-to-bag-btn-font-family);
  border: 1px solid #ec5e48;
  font-size: 20px;
  background: transparent;
  margin: 8px 0 0;
  color: var(--grey-70);

  &:hover {
    background: #ffb186;
    border-color: #ffb186;
    color: #000;
  }

  &:active {
    background: #ffb186;
    border-color: #ffb186;
    opacity: 0.7;
    color: #000;
  }
}

.baconHeaderInner {
  display: flex;
  margin: var(--bacon-package-header-inner-margin);
  width: 100%;
}

.baconHeader:not(.baconHeaderFull) .baconHeaderHeadline + .baconHeaderInner {
  margin: 0;
  width: auto;

  :global(body.today) & {
    margin: auto 0 h.rem(8px);
  }
}

.baconTitle {
  font-size: var(--bacon-package-title-font-size);
  background-color: transparent;
  border-color: transparent;
  color: var(--bacon-package-title-color);
  display: inline;
  font-family: var(--bacon-package-title-font-family);
  font-style: var(--bacon-package-title-font-style);
  font-weight: var(--bacon-package-title-font-weight);
  line-height: var(--bacon-package-title-line-height);
  margin: 0;
  padding-right: var(--bacon-package-title-padding-right);
  width: 100%;

  @include h.breakpoint-s-only {
    :global(.select) & {
      display: inline-block;
      max-width: 90%;
      width: fit-content;
    }
  }

  :global(.select) & {
    background-color: var(--white);
  }

  &::before {
    content: none;
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    margin-right: 1rem;
    border-color: inherit;
    border-top-width: 1px;
    border-top-style: solid;

    :global(.shows) &,
    :global(.entretenimiento) &,
    :global(.telemundo) & {
      content: '';
      border-color: var(--default-color);
    }
  }
}

.description {
  color: var(--bacon-package-description-font-color);
  margin-bottom: 12px;
  position: relative;
  font-family: var(--bacon-package-description-font-family);
  font-size: var(--bacon-package-description-font-size);
  line-height: var(--bacon-package-description-line-height);

  a {
    color: var(--bacon-package-link-color);

    :global(body.today) & {
      border-bottom: var(--bacon-package-link-underline-width) solid var(--bacon-package-link-underline-color);
      padding-bottom: var(--bacon-package-link-underline-padding);
    }

    &:hover {
      color: var(--bacon-package-link-hover-color);
      opacity: var(--bacon-package-link-hover-opacity);
    }

    &:active {
      opacity: var(--bacon-package-link-active-opacity);
    }
  }
}

.inlineArticle {
  --bacon-multiseller-margin-bottom: 4px;
  --bacon-package-description-line-height: 24px;
  overflow: visible;

  .baconScrollWrapper {
    width: 100vw;
    margin-left: -20px;
  }

  @include h.breakpoint-m {
    overflow: auto;

    .baconScrollWrapper {
      width: auto;
      margin-left: 0;
    }
  }
}

.disclaimer {
  font-family: var(--bacon-disclaimer-font-family, var(--tertiary-font));
  font-size: 12px;
}

.readMoreLink {
  margin-left: 4px;
  border-bottom: 2px solid #ffb186;

  &:hover {
    color: var(--default-color);
  }
}

.baconTitleLink {
  color: var(--default-color);
  font-family: var(--founders-cond);
  font-size: h.rem(16px);
  font-style: normal;
  line-height: unset;
  text-transform: uppercase;
  margin-left: h.rem(12px);

  :global(.select) & {
    color: var(--blue-60);
    font-family: var(--lato);
    font-size: h.rem(14px);
    line-height: h.rem(16px);
    margin-left: h.rem(16px);
  }

  :global(.shows) &,
  :global(.entretenimiento) &,
  :global(.telemundo) & {
    color: var(--default-color);
  }
}

.footer {
  display: flex;
  margin-top: 12px;
  height: 20px;
  font-family: var(--footer-font-family, var(--tertiary-font));
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--footer-font-color, #555);

  .logo {
    margin-left: 4px;
    height: 20px;
  }
}

.descriptionWrapper {
  color: var(--bacon-package-descriptionWrapper-font-color);
  font-family: var(--bacon-package-descriptionWrapper-font-family);
  font-size: var(--bacon-package-descriptionWrapper-font-size);
  line-height: var(--bacon-package-descriptionWrapper-line-height);
  width: 100%;

  :global(.select) & {
    color: var(--gray-80);
    margin: 0;
  }
}

.baconDisclaimerPagingDots,
.baconAddButtonPagingDots {
  margin-top: var(--bacon-package-paging-dots-margin-top);
  display: flex;
  justify-content: space-between;

  :global(.disclaimer-modal) {
    margin-top: var(--bacon-package-paging-dot-disclaimer-modal-margin-top);
    margin-bottom: var(--bacon-package-paging-dot-disclaimer-modal-margin-bottom);
  }
}

.baconPagingDots {
  align-self: var(--bacon-package-paging-dot-align-self);
  display: var(--bacon-package-paging-dots-display);
  flex-shrink: 0;
  margin: var(--bacon-package-paging-dot-margin);
  padding: 0 0 0 h.rem(12px);
  z-index: 2;

  .baconHeaderFull & {
    padding: 0 0 0 h.rem(32px);
  }
}

.baconPagingDot {
  background: transparent;
  border-radius: 100%;
  border: 1px solid var(--bacon-package-paging-dot-border-color);
  color: var(--bacon-package-paging-dot-color);
  display: block;
  height: var(--bacon-package-paging-dot-height);
  outline: none;
  padding: 0;
  width: var(--bacon-package-paging-dot-width);

  :global(.select) & {
    border-color: var(--blue-60);
  }

  & + & {
    margin-left: var(--bacon-package-paging-dot-spacing);
  }

  &:hover,
  &.baconPagingDotIsCurrent {
    border-color: currentcolor;
  }

  &.baconPagingDotIsCurrent {
    background-color: currentcolor;

    :global(.video-page) & {//active dot changes color for TODAY on canonical video
      border-color: var(--bacon-package-canonical-video-active-dot--color);
      background-color: var(--bacon-package-canonical-video-active-dot--color);
    }
  }
}

.centerAlign {
  margin-left: auto;
  margin-right: auto;
  max-width: 1160px;

  :global(body.today) & {
    max-width: 1260px;
  }
}

.baconScrollWrapper {
  align-items: stretch;
  display: flex;
  position: relative;
}

.baconScrollContainer {
  flex-grow: 1;
  scroll-snap-type: x mandatory;
  scroll-padding: var(--bacon-package-scroll-container-scroll-padding);
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  z-index: 0;
  padding: var(--bacon-package-scroll-container-padding, 0);

  &::-webkit-scrollbar {
    display: none;
  }

  @include h.breakpoint-m {
    padding-left: 0;
  }

  :global(body.today .video-page) & {
    padding: 0 h.rem(20px);

    @include h.breakpoint-m {
      padding: 0 h.rem(50px) 0 0;
    }
  }
}

.baconCardWrapper {
  flex-shrink: 0;
  scroll-snap-align: start;

  & + & {
    margin-left: var(--bacon-package-card-wrapper-spacing);
  }

  :global(.today .video-page) & {
    &:hover {
      h3 {
        opacity: var(--bacon-package-card-wrapper-h3-hover-opacity);

        a {
          color: var(--bacon-package-card-wrapper-h3-hover-color);
        }
      }
    }
  }

  &.default,
  &.oneOne,
  &.oneOneSmall,
  &.oneOneProduct,
  &.shopListByOneProduct,
  &.twoOneSmall,
  &.twoOneLarge,
  &.twoOneLargeVideoPage {
    width: var(--bacon-package-card-wrapper-width, #{h.rem(260px)});
  }

  &.author {
    width: #{h.rem(263px)};
  }

  &.twoOneLargeItems {
    width: #{h.rem(228px)};

    :global(.x-by-one__tease-category) {
      display: none;
    }
  }

  &.twoThreePortrait {
    width: #{h.rem(250px)};
  }

  @include h.breakpoint-m {
    &.twoOneLarge {
      width: #{h.rem(360px)};
    }

    &.twoOneLargeItems {
      width: #{h.rem(300px)};
    }

    &.twoOneLargeVideoPage {
      width: #{h.rem(220px)};
    }
  }
}

.baconPrevBtnContainer {
  background: var(--bacon-package-btn-container-bkg-color);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;

  @include h.breakpoint-m {
    opacity: 0;
    width: 0;
    transition: opacity 0.5s ease-in-out, width 0s  0.5s;

    &.baconPrevBtnActive {
      opacity: var(--opacity-100);
      width: var(--bacon-package-button-width);
      // remove width transition to make it `$bacon-package-button-width` immediately
      transition: opacity 0.5s ease-in-out;
    }
  }

  :global(.related-recipes) & {
    right: 100%;
  }

  :global(body.today .video-page) & {
    background: var(--black);
    display: none;
    justify-content: flex-start;
    padding: 0;
    height: 100%;

    @include h.breakpoint-m {
      display: flex;
    }
  }
}

.baconNextBtnContainer {
  flex-shrink: 0;
  width: var(--bacon-package-button-width);
  background: var(--bacon-package-btn-container-bkg-color);
  padding-left: h.rem(12px);

  :global(body.today .video-page) & {
    background: var(--black);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    height: 100%;
    z-index: 2;
    justify-content: flex-end;
    padding: 0;

    @include h.breakpoint-m {
      display: flex;
    }
  }
}

.baconNextBtnContainerNoPagination {
  display: none;
}

.baconBottomBorder {
  margin: 0;
  margin-top: 24px;
  border-top: 1px solid #ccc;

  @include h.breakpoint-s-only {
    margin-right: 20px;
  }
}

:global(body.today .x-by-one__tease-category) {
  justify-content: flex-end;
}
