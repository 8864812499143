/* stylelint-disable ramen/import-tokens-file */
@use 'assets/styles/utils/helper' as h;

.packageTitle {
  margin-left: var(--package-title--container--margin-left);

  @include h.breakpoint-x {
    max-width: none;
  }

  &.rail {
    @include h.breakpoint-m {
      margin-left: var(--package-title--container--rail--margin-left);
    }

    @include h.breakpoint-l {
      // grid columns and gutters are same size at L
      max-width: h.rem(11 * h.$gutter-desktop);
    }

    @include h.breakpoint-x {
      max-width: none;
    }
  }

  &.fullWidth::after {
    @include h.breakpoint-m {
      content: '';
      background: var(--package-title-background-after);
      display: var(--package-title-display-after);
      position: absolute;
      top: 0;
      left: 100%;
      width: h.$outer-gutter;
      height: calc(100% - 100px);
    }

    @include h.breakpoint-l {
      height: h.rem(340px);
      width: h.$outer-gutter * 2;
    }

    @include h.breakpoint-x {
      height: h.rem(400px);
    }
  }

  &.alternateDisplayFormat {
    background-color: transparent;
    margin-left: 100px;
    color: var(--white);
  }
}

// when the package title is in a rail layout
:global(.today) .packageTitle.rail .titleText::before {
  @include h.breakpoint-l {
    width: 116px;
  }

  @include h.breakpoint-x {
    width: 134px;
  }
}
