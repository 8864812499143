@use 'assets/styles/utils/helper' as h;

.baconCardsTwoByThreeContainer {
  --twoByThree--font-size: #{h.rem(16px)};
  --twoByThree--line-height: var(--leading-150);
  --twoByThree-button--height: #{h.rem(32px)};
  --twoByThree-button--font-family: var(--founders-cond);
  --twoByThree-button--font-size: #{h.rem(16px)};
  --twoByThree-button--color: var(--default-color);
  --twoByThree-button---border-color: var(--default-color);
  --twoByThree-button-description--color: var(--grey-60);
  --twoByThree-overlay--border-color: var(--default-color);
  --twoByThree-overlay-description--font-size: #{h.rem(14px)};
  --twoByThree-overlay-description--font-family: var(--publico-txt);

  :global(body.today) & {
    --twoByThree--font-size: #{h.rem(18px)};
    --twoByThree--font-weight: 400;
    --twoByThree-button--height: #{h.rem(48px)};
    --twoByThree-button--font-family: var(--secondary-font);
    --twoByThree-button--font-size: #{h.rem(19px)};
    --twoByThree-button--color: var(--black);
    --twoByThree-button-description--color: var(--grey-70);
    --twoByThree-overlay-description--font-size: #{h.rem(16px)};
    --twoByThree-overlay-description--font-family: var(--secondary-font);
    --twoByThree-overlay--border-color: transparent;
  }
}
