@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.articleDek {
  color: var(--article-dek--color);
  font-family: var(--article-dek--font-family);
  font-size: var(--article-dek--font-size);
  font-weight: var(--article-dek--font-weight);
  line-height: var(--article-dek--line-height);
  letter-spacing: var(--article-dek--letter-spacing);
  margin: var(--article-dek--margin);

  &.withImage {
    margin: var(--article-dek--with-image--margin);
  }

  &.isShoppingSection {
    color: var(--grey-70);
  }

  &.isLiveBreakingNews {
    font-size: var(--article-dek--font-size--compact);
    line-height: var(--article-dek--line-height--compact);
    margin-bottom: var(--article-dek--margin-bottom--compact);
  }
}

// TODO: pass this styling in from the Show Blog page
:global(.showBlogPage) .articleDek {
  color: var(--grey-70);
  font-size: 18px;
  font-style: italic;
  line-height: var(--leading-125);
  margin-bottom: 0;

  @include h.breakpoint-m {
    font-size: 20px;
  }
}
