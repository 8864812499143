@use 'assets/styles/utils/helper' as h;

.svg {
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.path {
  fill: var(--primary-color);
}

.container {
  display: inline-block;
  margin-right: h.rem(5px);

  &.small {
    width: auto;
    height: h.rem(15px);

    .svg {
      margin-bottom: h.rem(2px);
    }
  }

  &.medium {
    width: auto;
    height: h.rem(20px);

    .svg {
      margin-bottom: h.rem(4px);
    }
  }

  &.large {
    width: h.rem(18px);
    height: h.rem(18px);

    @include h.breakpoint-m {
      width: h.rem(24px);
      height: h.rem(24px);
    }

    .svg {
      margin-bottom: h.rem(4px);

      @include h.breakpoint-m {
        margin-bottom: h.rem(6px);
      }
    }
  }
}
