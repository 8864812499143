@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.card-overlay {
  @include h.border-gradient(var(--card-overlay--border-colors));
  border-top-width: var(--card-overlay--border-width);
  border-top-style: solid;
  background-color: var(--white);

  &--modal {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  &--absolute {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: var(--card-overlay--transform);
    transition: transform 400ms ease-out, opacity 100ms ease-in;
    z-index: 2;

    &.active {
      opacity: 1;
      transform: translateY(0);
      transition: transform 350ms ease-out, opacity 100ms linear 100ms;
    }
  }
}
