@use 'assets/styles/utils/helper' as h;

.omegaVideoSlate {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.omegaVideoSlate img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.omega-container {
  height: 100%;
  left: 0;
  max-height: 100vh;
  position: absolute;
  width: 100%;
  top: 0;
}
