@use 'assets/styles/utils/helper' as h;

:root {
  --image-credit--bottom: 8px;
  --image-credit--right: 8px;
  --image-credit--color: var(--white);
  --image-credit--font-family: var(--founders-mono);
  --image-credit--font-size: #{h.rem(10px)};
  --image-credit--word-spacing: -0.1em;
  --image-credit--letter-spacing: -0.04em;
  --image-credit--line-height: 1;
}

:global(body.today) {
  --image-credit--bottom: -22px;
  --image-credit--color: var(--grey-50);
  --image-credit--font-family: var(--secondary-font);
  --image-credit--font-size: #{h.rem(12px)};
  --image-credit--word-spacing: 0;
  --image-credit--letter-spacing: 0;
  --image-credit--line-height: 1.5;

  @include h.breakpoint-m {
    --image-credit--right: 0;
  }
}
