@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;
@use 'sass:math';

// TwoByThree
.baconCardsTwoByThreeContainer {
  height: 375px;
  position: relative;
  width: 100%;

  & .baconCardsTwoByThreeBgContent {
    height: 100%;
  }

  & .baconCardsTwoByThreeBgWrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & .baconCardsTwoByThreeGradient {
    background: linear-gradient(180deg, rgba(16, 32, 57, 0) 49.87%, rgba(16, 32, 57, 0.6) 100%);

    :global(body.better) & {
      background: linear-gradient(180deg, rgba(42, 5, 71, 0) 49.87%, rgba(42, 5, 71, 0.6) 100%);
    }

    :global(body.think) &,
    :global(body.mach) &,
    :global(body.globalcitizen) & {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 49.87%, rgba(0, 0, 0, 0.6) 100%);
    }

    :global(body.msnbc) & {
      background: linear-gradient(180deg, rgba(19, 82, 112, 0) 49.87%, rgba(19, 82, 112, 0.6) 100%);
    }

    :global(body.noticias) & {
      background: rgba(35, 40, 60, 0.8);
    }

    :global(body.today) & {
      background: linear-gradient(180deg, rgba(56, 5, 56, 0) 49.87%, rgba(56, 5, 56, 0.6) 100%);
    }
  }

  & .baconCardsTwoByThreeAdditionalGradient {
    opacity: 0.3;
    background: linear-gradient(146.31deg, rgba(48, 97, 255, 0.5) 0%, #3061ff 48.96%, #102039 100%);

    :global(body.better) & {
      background: linear-gradient(146.31deg, rgba(33, 209, 170, 0.5) 0%, #21d19e 48.96%, #2a0547 100%);
    }

    :global(body.globalcitizen) & {
      background: linear-gradient(146.31deg, rgba(182, 0, 26, 0.5) 0%, #b6001a 48.96%, #b2ccce 100%);
    }

    :global(body.mach) & {
      background: linear-gradient(146.31deg, rgba(206, 30, 108, 0.5) 0%, #5c00d8 48.96%, #5c00d8 100%);
    }

    :global(body.msnbc) & {
      background: linear-gradient(146.31deg, rgba(81, 241, 226, 0.5) 0%, #51f1e2 48.96%, #135270 100%);
    }

    :global(body.noticias) & {
      background: rgba(35, 40, 60, 0.8);
    }

    :global(body.think) & {
      background: linear-gradient(146.31deg, rgba(255, 80, 66, 0.5) 0%, #ff5042 50%, #380538 100%);
    }

    :global(body.today) & {
      background: linear-gradient(146.31deg, rgba(255, 80, 60, 0.5) 0%, #ff503c 50%, #380538 100%);
    }
  }

  &:hover {
    & .baconCardsTwoByThreeOverlay {
      align-items: center;
      border-color: var(--twoByThree-overlay--border-color);
      display: flex;
      flex-direction: column;
      height: 100%;
      opacity: 0.9;
      overflow: hidden;

      &.cardOverlayHover {
        transition:
          transform 350ms ease-out,
          opacity 100ms linear 100ms;
        transform: translateY(0);
      }
    }
  }

  & .baconCardsTwoByThreeTitleWrapper {
    height: 100%;
  }

  & .baconCardsTwoByThreeTitleLink {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
    z-index: 1;
  }

  & .baconCardsTwoByThreeTitle {
    color: var(--white);
    display: flex;
    font-family: var(--founders-cond);
    font-size: h.rem(24px);
    font-style: var(--normal);
    justify-content: center;
    line-height: var(--leading-100);
    margin-left: h.rem(20px);
    margin-right: h.rem(20px);
    padding-bottom: h.rem(24px);
    text-transform: uppercase;

    :global(body.think) & {
      font-family: var(--publico-hed);
      font-style: italic;
      text-transform: none;
    }

    :global(body.today) & {
      background-color: rgba(0, 0, 0, .5);
      padding: h.rem(20px 10px);
      margin-left: 0;
      margin-right: 0;
      line-height: 1.5;
      width: 100%;
      font-family: var(--primary-font);
      text-transform: none;

      &.noBG {
        background-color: transparent;
      }
    }
  }

  & .baconCardsTwoByThreeDescription {
    color: var(--twoByThree-button-description--color);
    display: flex;
    font-family: var(--twoByThree-overlay-description--font-family);
    font-size: var(--twoByThree-overlay-description--font-size);
    font-weight: var(--twoByThree--font-weight);
    justify-content: center;
    line-height: var(--leading-150);
    padding-left: h.rem(32px);
    padding-right: h.rem(32px);
    padding-top: h.rem(48px);
    width: 100%;
  }

  & .baconCardsTwoByThreeButton {
    align-items: center;
    border: solid 1px var(--default-color);
    color: var(--twoByThree-button--color);
    display: flex;
    font-family: var(--twoByThree-button--font-family);
    font-size: var(--twoByThree-button--font-size);
    height: var(--twoByThree-button--height);
    justify-content: center;
    margin-top: h.rem(16px);
    width: h.rem(186px);
    font-weight: var(--twoByThree-button--font-weight);

    .caret::before {
      display: inline-block;
      color: var(--default-color);
      font-size: h.rem(14px);
      padding: h.rem(2px 0 0 3px);
    }
  }
}
