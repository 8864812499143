@use 'assets/styles/utils/helper' as h;

:root {
  --bacon-multiseller-font-family: var(--default-font);
  --bacon-multiseller-header-font-family: var(--default-font);
  --bacon-multiseller-header-font-weight: 100;
  --bacon-multiseller-header-line-height: 100%;
  --bacon-multiseller-header-height: auto;
  --bacon-multiseller-header-color: var(--default-color);
  --bacon-multiseller-header-font-size: 20px;
  --bacon-multiseller-description-font-family: var(--publico-txt);
  --bacon-multiseller-description-font-size: 12px;
  --bacon-multiseller-description-height: auto;
  --bacon-multiseller-description-margin: 0;
  --bacon-multiseller-description-color: inherit;
  --bacon-multiseller-divider-color: var(--grey-40);
  --bacon-multiseller-seller-text-decoration-color: var(--default-color);
  --bacon-multiseller-seller-font-size: 14px;
  --bacon-multiseller-seller-margin: 0;
  --bacon-multiseller-seller-padding: 0 0 0 #{h.rem(4px)};
  --bacon-multiseller-seller-letter-spacing: -0.04rem;
  --bacon-multiseller-seller-thickness: inherit;
  --bacon-multiseller-seller-offset: inherit;
  --bacon-multiseller-price-color: var(--default-color);
  --bacon-multiseller-new-price-color: var(--default-color);
  --bacon-multiseller-new-price-padding-bottom: 0;
  --bacon-multiseller-price-hover-color: var(--default-color);
  --bacon-multiseller-price-font-weight: 100;
  --bacon-multiseller-price-hover-opacity: var(--opacity-70);
  --bacon-multiseller-price-active-opacity: var(--opacity-50);
  --bacon-multiseller-description-line-clamp: 2;
}

:global(body.select) {
  --bacon-multiseller-price-color: #555;
  --bacon-multiseller-new-price-color: var(--red-50);
  --bacon-multiseller-new-price-padding-bottom: 2px;
  --bacon-multiseller-font-family: var(--lato);
  --bacon-multiseller-header-font-family: var(--lato);
  --bacon-multiseller-header-font-weight: 900;
  --bacon-multiseller-header-color: var(--grey-70);
  --bacon-multiseller-price-font-weight: 700;
  --bacon-multiseller-price-hover-color: var(--blue-60);
  --bacon-multiseller-header-font-size: 18px;
  --bacon-multiseller-seller-text-decoration-color: var(--blue-60); // #0477C9
  --bacon-multiseller-divider-color: #ccc;
  --bacon-multiseller-header-line-height: 105%;
  --bacon-multiseller-header-height: 39px;
  --bacon-multiseller-description-height: 36px;
  --bacon-multiseller-description-font-family: var(--lato);
  --bacon-multiseller-description-color: #555;
  --bacon-multiseller-description-line-clamp: 3;
}

:global(body.today) {
  --bacon-multiseller-price-color: var(--grey-60);
  --bacon-multiseller-new-price-color: var(--orange-60);
  --bacon-multiseller-font-family: var(--secondary-font);
  --bacon-multiseller-header-font-family: var(--secondary-font);
  --bacon-multiseller-header-font-weight: 400;
  --bacon-multiseller-header-color: var(--grey-70);
  --bacon-multiseller-price-font-weight: 700;
  --bacon-multiseller-price-hover-color: var(--primary-color);
  --bacon-multiseller-header-font-size: 20px;
  --bacon-multiseller-seller-text-decoration-color: var(--accent-orange);
  --bacon-multiseller-divider-color: var(--grey-40);
  --bacon-multiseller-header-line-height: 125%;
  --bacon-multiseller-header-height: 50px;
  --bacon-multiseller-description-height: #{h.rem(42px)};
  --bacon-multiseller-description-font-family: var(--tertiary-font);
  --bacon-multiseller-description-font-size: #{h.rem(14px)};
  --bacon-multiseller-description-margin: 0 0 10px;
  --bacon-multiseller-description-color: var(--grey-60);
  --bacon-multiseller-seller-font-size: #{h.rem(16px)};
  --bacon-multiseller-seller-margin: 0;
  --bacon-multiseller-seller-padding: 0 0 0 #{h.rem(4px)};
  --bacon-multiseller-seller-letter-spacing: 0;
  --bacon-multiseller-seller-thickness: 2px;
  --bacon-multiseller-seller-offset: 3px;
  --bacon-multiseller-price-hover-opacity: 1;
  --bacon-multiseller-price-active-opacity: 1;
}
