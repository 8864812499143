@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.disclaimer {
  width: var(--ecommerce-disclaimer--width);
  background-color: var(--ecommerce-disclaimer--background-color);
  color: var(--ecommerce-disclaimer--color);
  font-family: var(--ecommerce-disclaimer--font-family);
  font-size: var(--ecommerce-disclaimer--font-size);
  line-height: var(--ecommerce-disclaimer--line-height);
  margin: var(--ecommerce-disclaimer--margin);
  min-height: inherit;
}

.content {
  font-style: var(--ecommerce-disclaimer--content--font-style);
  margin: var(--ecommerce-disclaimer--content--margin);
  padding: var(--ecommerce-disclaimer--content--padding);
  text-align: var(--ecommerce-disclaimer--content--text-align);
  line-height: var(--ecommerce-disclaimer--content--line-height);
  font-weight: var(--ecommerce-disclaimer--content--font-weight);
}

.content a {
  border-bottom: var(--ecommerce-disclaimer--link--border-bottom);
  color: var(--ecommerce-disclaimer--link--color);
  font-weight: var(--ecommerce-disclaimer--link--font-weight);
  padding-bottom: var(--ecommerce-disclaimer--link--padding-bottom);
  transition: color .25s;

  &:hover {
    opacity: var(--ecommerce-disclaimer--link--hover-opacity);
    color: var(--ecommerce-disclaimer--link--hover-color);
  }

  &:active {
    opacity: var(--default-active-opacity);
  }
}

.disclaimerAlternate {
  background: inherit;
  margin-bottom: h.rem(46px);
  margin-left: 0;
  margin-right: 0;
}

.disclaimerAlternate .content {
  border-bottom: 1px solid var(--grey-40);
  border-top: 1px solid var(--grey-40);
  font-size: var(--text-12);
  font-style: normal;
  line-height: var(--text-18);
  padding: h.rem(24px) 0;
}
