@use 'assets/styles/utils/helper' as h;

$mobile-grid-column: '(8.33333% - 2.16667vw)' !default;
$package-title-see-all-link-font-size: (s: h.rem(18px), m: h.rem(20px)) !default;

:root {
  --package-title--container--padding: 2rem;
  --package-title--container-description--padding-bottom: 1.5rem;
  --package-title--container--margin-left: calc(#{h.$outer-gutter} + #{h.$gutter-mobile} + #{$mobile-grid-column});
  --package-title--container--rail--margin-left: #{h.rem(100px)};
  --package-title--container--display: flex;
  --package-title-description-lineheight: 125%;
  --package-title-description-margin: 6px 0 0;
  --package-title-description-fontfamily: var(--publico-hed);
  --package-title-description-font-size: #{h.rem(14px)};
  --package-title-description-color: var(--white);
  --package-title-description-color--alternate: var(--white);
  --package-title-background: var(--default-color);
  --package-title-background-after: inherit;
  --package-title-display-after: block;
  --package-title-background--alternate: var(--default-color);
  --package-title-see-all-link-font: var(--founders-cond);
  --package-title-see-all-link-color: var(--white);
  --package-title-see-all-link-alt-color: var(--default-color);
  --package-title-see-all-link-underline: 3px solid;
  --package-title-see-all-link-underline-color: var(--white);
  --package-title-see-all-link-bottom-spacing: 0;
  --package-title-see-all-link-font-weight: 400;
  --package-title-title-text-color: var(--white);
  --package-title-title-text-color--alternate: var(--white);
  --package-title-title-text-background: var(--default-color);
  --package-title-title-text-background--alternate: var(--default-color);
  --package-title-title-text-font-style: normal;
  --package-title-title-text-font-weight: normal;
  --package-title-title-text-text-transform: none;
  --package-title-title-before-display: block;
  --package-title-text-font-family: var(--founders-cond);
  --package-title-text-font-size: var(--text-24);
  --package-title-text-line-height: var(--leading-100);
  --package-title-title-text-line-color: var(--default-color);

  @include h.breakpoint-m {
    --package-title--container--padding: 2rem 3rem;
    --package-title-text-font-size: var(--text-32);
  }

  @include h.breakpoint-l {
    --package-title--container--margin-left: #{h.rem(3 * h.$gutter-desktop)};
    --package-title-text-font-size: var(--text-48);
    --package-title--container--rail--margin-left: auto;
  }

  @include h.breakpoint-x {
    --package-title--container--margin-left: #{h.rem((2 * 60px) + (2 * h.$gutter-desktop))};
    --package-title--container--rail--margin-left: #{h.rem((2 * 60px) + (2 * h.$gutter-desktop))};
  }
}

body.news {
  --package-title-background: var(--blue-70);
  --package-title-description-margin: 12px 0 1px 0;
  --package-title-title-text-background: var(--blue-70);
}

body.today {
  --package-title-description-margin: 12px 0 1px 0;
  --package-title-description-color: var(--grey-70);
  --package-title-see-all-link-underline: none;
  --package-title-background: transparent;
  --package-title-background--alternate: transparent;
  --package-title-title-text-color: var(--grey-60);
  --package-title-title-text-background: transparent;
  --package-title-title-text-background--alternate: transparent;
  --package-title-title-text-font-style: normal;
  --package-title-title-text-font-weight: 400;
  --package-title-text-font-family: var(--primary-font);
  --package-title--container--margin-left: 0;
  --package-title--container--rail--margin-left: 0;
  --package-title--container--padding: 0 #{h.rem(30px)} #{h.rem(24px)} #{h.rem(20px)};
  --package-title--container--display: block;
  --package-title-title-before-display: none;
  --package-title-text-font-size: #{h.rem(32px)};
  --package-title-text-line-height: 1.5;
  --package-title-description-fontfamily: var(--secondary-font);
  --package-title-description-font-size: var(--text-18);
  --package-title-display-after: none;
  --package-title-arrow-font-size: #{h.rem(28px)};

  @include h.breakpoint-m {
    --package-title--container--padding: 0 0 #{h.rem(32px)};
    --package-title--container-description--padding-bottom: #{h.rem(16px)};
  }

  @include h.breakpoint-l {
    --package-title--container--padding: 0 0 #{h.rem(40px)};
    --package-title-text-font-size: #{h.rem(48px)};
  }

  .package-flex-grid {
    --package-title--container--padding: 0 #{h.rem(10px)} #{h.rem(14px)} 0;

    @include h.breakpoint-m {
      --package-title--container--padding: 0 0 #{h.rem(22px)};
    }

    @include h.breakpoint-l {
      --package-title--container--padding: 0 0 #{h.rem(20px)};
    }
  }

  .package-title__title-text--small {
    --package-title-text-font-size: #{h.rem(28px)};

    @include h.breakpoint-m {
      --package-title-text-font-size: #{h.rem(32px)};
    }
  }

  .bacon-pkg-title {
    --package-title--container--padding: 0 #{h.rem(10px)} 0 0;

    @include h.breakpoint-m {
      --package-title--container--padding: 0;
    }
  }

  .bacon-pkg-title--inline-article {
    --package-title-text-font-size: #{h.rem(28px)};
  }

  .video-pkg-title {
    --package-title--container--padding: #{h.rem(0 30px 16px 20px)};

    @include h.breakpoint-m {
      --package-title--container--padding: #{h.rem(16px)};
    }
  }
}

body.msnbc {
  --package-title-description-margin: 11px 0 2px 0;
}

body.noticias,
body.deportes {
  --package-title-background: var(--blue-60);
  --package-title-see-all-link-underline-color: var(--default-color);
  --package-title-see-all-link-bottom-spacing: 4px;
  --package-title-title-text-background: transparent;
  --package-title-title-text-font-weight: 600;
  --package-title-text-font-size: var(--text-32);
}

body.telemundo,
body.entretenimiento,
body.shows {
  --package-title-description-color: var(--blue-60);
  --package-title-description-margin: 12px 0 1px 0;
  --package-title-see-all-link-color: var(--default-color);
  --package-title-see-all-link-underline: none;
  --package-title-background: var(--neutral-30);
  --package-title-title-text-color: var(--blue-60);
  --package-title-title-text-background: var(--default-neutral);
  --package-title-title-text-font-weight: 600;
  --package-title-text-font-size: var(--text-32);
}

body.select {
  --package-title-background: var(--blue-60);
  --package-title-background--alternate: var(--blue-60);
  --package-title-title-text-background: var(--blue-60);
  --package-title-title-text-background--alternate: var(--blue-60);
  --package-title-description-fontfamily: var(--lato);
  --package-title-see-all-link-font: var(--lato);
  --package-title-see-all-link-font-weight: 900;
  --package-title-title-text-color: var(--white);
  --package-title-text-font-family: var(--lato);
  --package-title-title-text-font-weight: 900;
  --package-title-text-font-size: #{h.rem(22px)};
  --package-title-text-line-height: #{h.rem(24px)};

  @include h.breakpoint-m {
    --package-title-text-font-size: #{h.rem(36px)};
    --package-title-text-line-height: #{h.rem(40px)};
  }
}

body.eonline {
  --package-title-background: linear-gradient(to right, var(--teal-70), var(--magenta-70));
  --package-title-background-after: var(--magenta-70);
  --package-title-title-text-background: transparent;
  --package-title-title-text-font-weight: bold;
}
