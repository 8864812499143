@use 'assets/styles/utils/helper' as h;

.info-tile {
  align-items: baseline;
  display: flex;
  margin-right: h.rem(30px);

  .info-tile__key {
    color: var(--grey-70);
    font-family: var(--secondary-font);
    font-size: h.rem(14px);
    font-weight: bold;
    height: 100%;
    margin-right: 2px;
  }

  .info-tile__value {
    font-family: var(--secondary-font);
    font-size: h.rem(14px);
    max-width: h.rem(95px);
  }
}

@media print {
  .info-tile {
    margin-bottom: h.rem(8px);

    .info-tile__key {
      font-family: var(--secondary-font);
      font-weight: bold;
      margin-right: h.rem(3px);
      min-width: h.rem(50px);
      text-transform: uppercase;
    }

    .info-tile__value {
      font-size: h.rem(12px);
    }
  }
}
